import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';
import { server } from '../../api/apiservice';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const { token } = useParams();
  const navigate = useNavigate();

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${server}userRoutes/resetPassword/${token}`, { password });
      toast.success(data.message, {
        position: "top-right",
        autoClose: 2000,
      });
      setTimeout(() => navigate('/'), 2000); // Redirect to login page
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || 'An error occurred. Please try again.', {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  return (

    <div className="reset-password-container">
      <div className="reset-password-box">
        <h1>Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="password">New Password</label>
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                required
              />
              <span
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Show eye or eye-slash icon */}
              </span>
            </div>
          </div>
          <button type="submit" className="reset-button">Reset Password</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;
