import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Notifications.css";
import { server } from "../api/apiservice";
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [users, setUserData] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await axios.get(`${server}notifications/getnotifications`);
      setNotifications(response.data);

      const unreadNotifications = response.data.some(
        (notification) => !notification.read
      );
      setHasUnread(unreadNotifications);
    } catch (err) {
      setError("Error fetching notifications. Please try again later.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const toggleNotifications = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setHasUnread(false);
    }
  };

  const getNotificationMessage = (type, name, daysLeft) => {
    const messages = {
      birthday: [
        `🎉 ${daysLeft} days left until ${name}'s birthday! Prepare the celebrations!`,
        `🥳 Only ${daysLeft} days to go for ${name}'s birthday!`,
        `🎂 It's ${name}'s birthday in ${daysLeft} days!`,
        `🎂 It's ${name}'s birthday tomorrow!`,
        `🎈 Happy Birthday, ${name}! Wishing you a fantastic year ahead!`,
      ],
      anniversary: [
        `✨ ${daysLeft} days until ${name}'s work anniversary! Congratulate them soon!`,
        `🎊 Only ${daysLeft} days until ${name}'s work anniversary!`,
        `📅 ${name}'s work anniversary is in ${daysLeft} days!`,
        `📅 Tomorrow marks ${name}'s work anniversary!`,
        `👏 Happy Work Anniversary, ${name}! Thanks for being a part of the team!`,
      ],
      festival: [
        `🎉 ${daysLeft} days left until ${name} celebration! Get ready!`,
        `🥳 Just ${daysLeft} days to go for ${name} celebration!`,
        `🎈 ${name} is in ${daysLeft} days! Get your festive spirit on!`,
        `🎈 Tomorrow is ${name}! Get your festive spirit on!`,
        `🎉 Happy ${name}! Enjoy the festival with joy and warmth!`,
      ],
    };
    return messages[type][daysLeft <= 1 ? 3 : 5 - daysLeft];
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;
      try {
        const { data } = await axios.get(
          `${server}auth/dashboard`,
          {
            headers: { Authorization: `${token} `},
          }
        );
        const { name, role, dateOfBirth, joiningDate, profileImage } = data;
        console.log("Profile Image URL:", data.profileImage);
        console.log("URLllllllllll:", data);
        setUserData(data); // Assuming data contains user info
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <>
      <div className="notification-button-container">
        <button
          className={`notification-button ${hasUnread ? "active" : ""}`}
          onClick={toggleNotifications}
        >
          {hasUnread ? (
            <i className="fa-solid fa-bell" style={{ color: "##007BFF" }}></i>
          ) : (
            <i className="fa-regular fa-bell" style={{ color: "blue" }}></i>
          )}
          {hasUnread && (
            <span className="notification-indicator">{notifications.length}</span>
          )}
        </button>
      </div>
      {isOpen && (
        <div className="notifications-overlay" onClick={toggleNotifications}>
          <div className="notifications-panel" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={toggleNotifications}>
              &times;
            </button>
            <h2>Notifications</h2>
            {loading ? (
              <div className="loading">Loading notifications...</div>
            ) : error ? (
              <div className="error">{error}</div>
            ) : (
              <>
                {notifications.map((notification, index) => (
                  <div key={index} className={`notification-card ${notification.type}-card`}>
                    <h3>{notification.type === "birthday" ? "Birthday Wishes" : notification.type === "anniversary" ? "Anniversary Wishes" : "Festival Notifications"}</h3>
                    <div className="notification-item">
                      <img 
                       src={
                        notification.type === "festival"
                          ? "https://blog.raynatours.com/wp-content/uploads/2022/11/Festivals-in-December.jpg"
                          : users.id?.profileImage
                          ? `${server}${users.id.profileImage}`
                          : "profilemen.avif"
                      }
                        alt="celebration"
                        className="notification-background"
                      />
                      <div className="notification-text">
                        <h4>{notification.employee?.name || notification.festival}</h4>
                        <p>
                          {getNotificationMessage(
                            notification.type,
                            notification.employee?.name || notification.festival,
                            notification.daysLeft
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                {notifications.length === 0 && <p>No notifications for today.</p>}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Notifications;
