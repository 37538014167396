import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './SelfRatingForm.css'
import { server } from '../../api/apiservice';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';  // Import toast
import 'react-toastify/dist/ReactToastify.css';  // Import styles
import { IoMdArrowRoundBack } from "react-icons/io";

const SelfRatingForm = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [ratings, setRatings] = useState({});
    const [employeeId, setEmployeeId] = useState(null);
    const [employeeName, setEmployeeName] = useState(null);
    const [employeeMail, setEmployeeMail] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formAlreadySubmitted, setFormAlreadySubmitted] = useState(false);
    const { id } = useParams();  // Get form ID from the URL
    const navigate = useNavigate();  // To handle navigation

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token) {
            const decodedToken = jwtDecode(token);
            setEmployeeId(decodedToken.id);
            setEmployeeName(decodedToken.name);
            setEmployeeMail(decodedToken.email);
        }

        axios.get(`${server}admin/forms`)
            .then(response => {
                setForms(response.data);
                console.log("response.data", response.data);

                // Automatically select the form if an ID is present in the URL
                if (id) {
                    const selected = response.data.find(form => form._id === id);
                    setSelectedForm(selected);
                }
            })
            .catch(error => {
                console.error('Error fetching forms:', error);
            });

        // Check if the employee has already submitted a form
        if (employeeId) {
            axios.get(`${server}ratings/${employeeId}`)
                .then(response => {
                    if (response.data.some(rating => rating.formId === id)) {
                        setFormAlreadySubmitted(true);
                    }
                })
                .catch(error => {
                    console.error('Error checking submission status:', error);
                });
        }

    }, [id, employeeId]);

    const handleFormSelection = (formId) => {
        // Navigate to the form details page
        navigate(`/form/${formId}`);
    };

   

    const handleRatingChange = (questionIndex, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [questionIndex]: value,
        }));
    };
    const handleSubmit = () => {
        if (!employeeId) {
            console.error('Employee ID is null or undefined');
            return;
        }

        // Check if all questions are answered
        const allAnswered = selectedForm.questions.every((question, index) => {
            return ratings[index] !== undefined && ratings[index] !== '';
        });

        if (!allAnswered) {
            toast.error('Please answer all questions before submitting.', {
                position: "top-right",
                autoClose: 2000,
            });
            return;
        }


        const selfRatingData = {
            employeeId: employeeId,
            employeeName: employeeName,
            employeeMail: employeeMail,
            formId: selectedForm._id,
            raterRole: 'self',
            ratings: selectedForm.questions.map((question, index) => ({
                questionIndex: index,
                response: ratings[index] || ''
            }))
        };


        axios.post(`${server}userRoutes/submit-rating`, selfRatingData)
            .then(response => {
                toast.success('Rating submitted successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                });
                setIsSubmitted(true); // Set isSubmitted to true to hide the form

                // Delay navigation
                setTimeout(() => {
                    navigate('/employee-dashboard');
                }, 2000); // 2 seconds delay

               
            })

            .catch(error => {
                console.error('Error submitting rating:', error);
                toast.error("Form already submitted", {
                    position: "top-right",
                    autoClose: 2000,
                })
            });


    };

    return (
        <div className="self-rating-form">

            {formAlreadySubmitted ? (
                <div>
                    <button onClick={() => navigate(-1)} className="back-button">
                        <IoMdArrowRoundBack />
                    </button>
                    <h4>Your Self rating form already submitted. Wait for the next form.</h4>
                </div>
            ) : isSubmitted ? (
                <h4>Thank you! Your rating has been submitted.</h4>
            ) : (
                <>
                    <h3>Select a Form to Rate Yourself</h3>
                    <div>
                        {forms.map(form => (
                            <button key={form._id} onClick={() => handleFormSelection(form._id)} className='form-title-button'>
                                {form.title}
                            </button>
                        ))}
                    </div>
                    {selectedForm && selectedForm.questions && (
                        <div className="form-details">
                            <h3 className='selected-form-title'>{selectedForm.title}</h3>
                            {selectedForm.questions.map((question, index) => (
                                <div key={index} className="form-fields">
                                    <label>{question.label}</label>
                                    {question.options.length > 0 ? (
                                        <select
                                            className='form-selects'
                                            value={ratings[index] || ''}
                                            onChange={(e) => handleRatingChange(index, e.target.value)} >
                                            <option value="" className='form-options'>Select an option</option>
                                            {question.options.map((option, i) => (
                                                <option key={i} value={option} className='form-options-option'  >
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            value={ratings[index] || ''}
                                            onChange={(e) => handleRatingChange(index, e.target.value)}

                                        />
                                    )}
                                </div>
                            ))}
                            <button onClick={handleSubmit}>Submit Rating</button>
                        </div>
                    )}
                </>

            )}
            <ToastContainer />
        </div>
    );
};

export default SelfRatingForm;









