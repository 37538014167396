// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';
// import './Home.css'

// const Home = () => {
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [users, setUsers] = useState([]);


//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check if the user is logged in by checking for the token in localStorage
//     const token = localStorage.getItem('token');
//     if (token) {
//       setIsLoggedIn(true);
//     } else {
//       setIsLoggedIn(false);
//     }
//   }, [navigate]);

//   useEffect(() => {
//     // Fetch all users
//     const fetchUsers = async () => {
//       try {
//         const response = await axios.get(`${server}userRoutes/users');
//         setUsers(response.data); // Set users to state
//       } catch (error) {
//         console.error('Error fetching users:', error);
//       }
//     };

//     fetchUsers();
//   }, []);



//   return (
//     <>
//       {/* Navigation Bar */}
//       <header className="navbar">
//         <div className="navbar-brand">Performance Appraisal Platform</div>
//         <nav className="navbar-nav">
//           <Link to="/login" className="navbar-link">Login</Link>
//           <Link to="/signup" className="navbar-link">Signup</Link>
//         </nav>
//       </header>

//       {/* Main Content */}
//       <div className="container">
//         <div className="content">
//           <h1>Welcome to the Performance Appraisal Platform</h1>

//           {!isLoggedIn ? (
//             <div className="auth-buttons">
//               <Link to="/login" className="btn btn-primary">Login</Link>
//               <Link to="/signup" className="btn btn-secondary">Signup</Link>
//             </div>
//           ) : (
//             <div/>
//           )}
//             {/* Horizontal Scrolling Users */}
//             <div className="users-scroll-container">
//             <div className="users-scroll">
//               {users.map((user) => (
//                 <div key={user._id} className="user-card">
//                   <img src="profilemen.avif" alt={user.name} className="user-avatar" />
//                   <h4>{user.name}</h4>
//                   <p>{user.role}</p>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Home;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css';
import { server } from '../api/apiservice';
import Login from '../components/Auth/Login';
import Signup from '../components/Auth/Signup';
const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [users, setUsers] = useState([]);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  const [showLogin, setShowLogin] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, [navigate]);



  return (

    <div className="home-page">
      <header className="navbar">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="navbar-brand">
            <img src="123.jpg" alt="Logo" className="navbar-logo" />
          </div>
          <div className="navbar-brand">
            Sumeru Digital Solutions
          </div>
        </div>
        <nav className="navbar-nav">
        </nav>
      </header>
      <div style={{ textAlign: "center", color: "#fff", padding: "10px 0" }}>
        <h1>Welcome to the Performance Appraisal Platform</h1>
      </div>

      <div className='lp'>
        {showLogin ? (
          <Login onSignupClick={() => setShowLogin(false)} />
        ) : (
          <Signup onLoginClick={() => setShowLogin(true)} />
        )}
      </div>
    </div>
  );
};

export default Home;


