import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './UpdateProfile.css';
import { server } from '../api/apiservice';
import { FaUserEdit } from "react-icons/fa";
const UpdateProfile = () => {
  const [userData, setUserData] = useState({
    name: '',
    role: '',
    dateOfBirth: '',
    joiningDate: '',
    manager_id: '',
    employeeID: '',
    phoneNO: '',
  });

  const [profileImage, setProfileImage] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const notificationRef = useRef(null);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setMessage('No token found, please log in again.');
          setMessageType('error');
          return;
        }

        const { data } = await axios.get(`${server}auth/dashboard`, {
          headers: { Authorization: `${token}` }
        });

        const { name, role, dateOfBirth, joiningDate } = data;
        setUserData({
          name,
          role,
          dateOfBirth: formatDate(dateOfBirth),
          joiningDate: formatDate(joiningDate)
        });
      } catch (error) {
        setMessage('Error fetching user data: ' + (error.response ? error.response.data.message : 'Network Error'));
        setMessageType('error');
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked target is outside the notification container
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('No token found, please log in again.');
        setMessageType('error');
        return;
      }

      const formData = new FormData();
      formData.append('name', userData.name);
      formData.append('role', userData.role);
      formData.append('employeeID', userData.employeeID);
      formData.append('phoneNO', userData.phoneNO);
      formData.append('dateOfBirth', new Date(userData.dateOfBirth).toISOString());
      formData.append('joiningDate', new Date(userData.joiningDate).toISOString());

      if (profileImage) {
        formData.append('profileImage', profileImage);
      }

      const { data } = await axios.put(`${server}auth/updateProfile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `${token}`
        }
      });

      setMessage('Profile updated successfully!');
      setMessageType('success');
      // Update userData with the latest information from the server
      setUserData({
        name: data.result.name || userData.name,
        role: data.result.role || userData.role,
        employeeID: data.result.employeeID || userData.employeeID,
        phoneNO: data.result.phoneNO || userData.phoneNO,
        dateOfBirth: formatDate(data.result.dateOfBirth) || userData.dateOfBirth,
        joiningDate: formatDate(data.result.joiningDate) || userData.joiningDate,
        manager_id: data.result.manager_id || userData.manager_id,
      });
      setProfileImage(null); // Reset profile image
      setTimeout(() => setMessage(''), 1000); // Clear message after 3 seconds

      // Close the form after successful submission
      setIsOpen(false);

    } catch (error) {
      setMessage('Error updating profile: ' + (error.response ? error.response.data.message : 'Network Error'));
      setMessageType('error');
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const toggleForm = () => {
    setIsOpen((prev) => !prev);
  };
  // console.log("dcvbknbcjnc ", userData);


  return (
    <>
      <div className="update-button-container">
        <button className="update-button-click " onClick={toggleForm} style={{ border: "none" }}>

          <i class="fa-regular fa-pen-to-square"></i>

        </button>
      </div>

      {isOpen && (
        <div className='update-profile-overlay'>
          <div ref={notificationRef} className="update-profile-container">
            <h2>Update Profile</h2>
            {message && <p className={`message ${messageType}`}>{message}</p>}
            {isLoading && <p>Loading...</p>}
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Role:
                <input
                  type="text"
                  name="role"
                  value={userData.role}
                  onChange={handleChange}
                  readOnly
                />
              </label>
              <label>
                Date of Birth:
                <input
                  type="date"
                  name="dateOfBirth"
                  value={userData.dateOfBirth}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Joining Date:
                <input
                  type="date"
                  name="joiningDate"
                  value={userData.joiningDate}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Profile Image:
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                {/* {profileImage && <p>Selected file: {profileImage.name}</p>} */}
              </label>
              <button type="submit" disabled={isLoading}>
                Update Profile
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateProfile;
