import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import EmployeeDashboard from './components/Employee/EmployeeDashboard';
import FormBuilder from './components/Admin/FormBuilder'
import ManagerDashboard from './components/Manager/ManagerDashboard';
import CeoDashboard from './components/CEO/CeoDashboard'
import HRDashboard from './components/HR/HrDashboard';
import SidebarAll from './components/sidebar';
import ResetPassword from './components/Auth/ResetPassword.jsx';
// import ResetPassword from './components/Auth/ResetPassword.jsx';
import FormDetails from './components/Employee/FormDetails.jsx';
import Notifications from './components/Notifications.jsx';
import UpdateProfile from './components/UpdateProfile.jsx';
import ProfileUpdate from './components/ProfileUpdate/ProfileUpdate.jsx'
import ApplyLeave from './leaveData/ApplyLeave.jsx';
import ApproveLeave from './leaveData/ApproveLeave.jsx';
import Navigation from './components/NavBar/NavigationBar.jsx';
import EmployeeFeedback from './components/feedback/EmployeeFeedback.js';
import MainDashboard from './components/DashBoard/MainDashboard.jsx';
import LeaveHistory from './leaveData/LeaveHistory.jsx';
import AllUsersLeaveHistory from './leaveData/AllUsersLeaveHistory.jsx';
import ProfileDetails from './components/ProfileDetails/ProfileDetails.jsx';
import EmployeeDash from './components/EmployeeDash/EmployeeDash.jsx';
import EmployeeProfileDetails from './components/EmpProfileDetails/EmployeeProfileDetails.jsx';

import Employee from './components/P/Employee.jsx';
import LeaveCalendar from './leaveData/leaveCalender.jsx';
import Graph from './components/graph/Graph.js';



const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/employee-dashboard" element={<EmployeeDashboard />} />
        <Route path="/admin-dashboard" element={<FormBuilder />} />
        <Route path="/manager-dashboard" element={<ManagerDashboard />} />
        <Route path="/ceo-dashboard" element={<CeoDashboard />} />
        <Route path="/hr-dashboard" element={<HRDashboard />} />
        <Route path="/SidebarAll" element={<SidebarAll />} />
        <Route path="/ResetPassword/:token" element={<ResetPassword />} />
        <Route path="/form/:id" element={<FormDetails />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/updateProfile" element={<UpdateProfile />} />
        <Route path="/profileUpdate" element={<ProfileUpdate />} />
        <Route path="/applyLeave" element={<ApplyLeave />} />
        <Route path="/approveLeave/:token" element={<ApproveLeave />} />
        <Route path ="/navigation" element={<Navigation/>}/>
        <Route path="/employeeFeedback" element ={<EmployeeFeedback/>}/>
        <Route path="/leaveHistory" element={<LeaveHistory />} />
        <Route path="/allUsersLeaveHistory" element={<AllUsersLeaveHistory />} />

        <Route path="/mainDashboard" element={<MainDashboard />} />
        <Route path="/profile-details" element={<ProfileDetails />} />
        <Route path="/employee-dash" element={<EmployeeDash />} />
        <Route path="/employeeProfileDetails" element={<EmployeeProfileDetails />} />

        <Route path="/employee" element={<Employee />} />
        <Route path="/leave-calender" element={<LeaveCalendar/>}/>
        <Route path="/graph" element={<Graph/>}/>

      </Routes>
    </Router>
  );
};

export default App;



// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
// import Home from './pages/Home';
// import Signup from './components/Auth/Signup';
// import EmployeeDashboard from './components/Employee/EmployeeDashboard';
// import FormBuilder from './components/Admin/FormBuilder';
// import ManagerDashboard from './components/Manager/ManagerDashboard';
// import CeoDashboard from './components/CEO/CeoDashboard';
// import HRDashboard from './components/HR/HrDashboard';
// import SidebarAll from './components/sidebar';
// import ResetPassword from './components/Auth/ResetPassword.jsx';
// import FormDetails from './components/Employee/FormDetails.jsx';
// import Notifications from './components/Notifications.jsx';
// import UpdateProfile from './components/UpdateProfile.jsx';
// import ProfileUpdate from './components/ProfileUpdate/ProfileUpdate.jsx';
// import ApplyLeave from './leaveData/ApplyLeave.jsx';
// import ApproveLeave from './leaveData/ApproveLeave.jsx';
// import Navigation from './components/NavBar/NavigationBar.jsx';
// import EmployeeFeedback from './components/feedback/EmployeeFeedback.js';
// import MainDashboard from './components/DashBoard/MainDashboard.jsx';
// import LeaveHistory from './leaveData/LeaveHistory.jsx';
// import AllUsersLeaveHistory from './leaveData/AllUsersLeaveHistory.jsx';
// import ProfileDetails from './components/ProfileDetails/ProfileDetails.jsx';
// import EmployeeDash from './components/EmployeeDash/EmployeeDash.jsx';
// import EmployeeProfileDetails from './components/EmpProfileDetails/EmployeeProfileDetails.jsx';
// import Employee from './components/P/Employee.jsx';
// import LeaveCalendar from './leaveData/leaveCalender.jsx';
// import Graph from './components/graph/Graph.js';


// const isAuthenticated = () => {
//   return !!localStorage.getItem('token');
// };


// const PrivateRoute = ({ element }) => {
//   const location = useLocation();
//   return isAuthenticated() ? (
//     element
//   ) : (
//     <Navigate to="/" replace state={{ from: location.pathname }} />
//   );
// };


// const PublicRoute = ({ element }) => {
//   const location = useLocation();
//   return !isAuthenticated() ? element : <Navigate to="/employee-dashboard" replace state={{ from: location.pathname }} />; // Redirect authenticated users to dashboard
// };
// const LocationLogger = () => {
//   const location = useLocation();
//   React.useEffect(() => {
//     console.log('Current location:', location.pathname);
//   }, [location]);

//   return null; // No UI needed for this component
// };
// const App = () => {

//   return (
//     <Router>
//       <LocationLogger />
//       <Routes>


//         <Route path="" element={<PublicRoute element={<Home />} />} />
//         <Route path="/signup" element={<PublicRoute element={<Signup />} />} />


//         <Route path="/employee-dashboard" element={<PrivateRoute element={<EmployeeDashboard />} />} />
//         <Route path="/admin-dashboard" element={<PrivateRoute element={<FormBuilder />} />} />
//         <Route path="/manager-dashboard" element={<PrivateRoute element={<ManagerDashboard />} />} />
//         <Route path="/ceo-dashboard" element={<PrivateRoute element={<CeoDashboard />} />} />
//         <Route path="/hr-dashboard" element={<PrivateRoute element={<HRDashboard />} />} />
//         <Route path="/SidebarAll" element={<PrivateRoute element={<SidebarAll />} />} />
//         <Route path="/ResetPassword/:token" element={<PublicRoute element={<ResetPassword />} />} />
//         <Route path="/form/:id" element={<PrivateRoute element={<FormDetails />} />} />
//         <Route path="/Notifications" element={<PrivateRoute element={<Notifications />} />} />
//         <Route path="/updateProfile" element={<PrivateRoute element={<UpdateProfile />} />} />
//         <Route path="/profileUpdate" element={<PrivateRoute element={<ProfileUpdate />} />} />
//         <Route path="/applyLeave" element={<PrivateRoute element={<ApplyLeave />} />} />
//         <Route path="/approveLeave/:token" element={<PrivateRoute element={<ApproveLeave />} />} />
//         <Route path="/navigation" element={<PrivateRoute element={<Navigation />} />} />
//         <Route path="/employeeFeedback" element={<PrivateRoute element={<EmployeeFeedback />} />} />
//         <Route path="/leaveHistory" element={<PrivateRoute element={<LeaveHistory />} />} />
//         <Route path="/allUsersLeaveHistory" element={<PrivateRoute element={<AllUsersLeaveHistory />} />} />
//         <Route path="/mainDashboard" element={<PrivateRoute element={<MainDashboard />} />} />
//         <Route path="/profile-details" element={<PrivateRoute element={<ProfileDetails />} />} />
//         <Route path="/employee-dash" element={<PrivateRoute element={<EmployeeDash />} />} />
//         <Route path="/employeeProfileDetails" element={<PrivateRoute element={<EmployeeProfileDetails />} />} />
//         <Route path="/employee" element={<PrivateRoute element={<Employee />} />} />
//         <Route path="/leave-calender" element={<PrivateRoute element={<LeaveCalendar />} />} />
//         <Route path="/graph" element={<PrivateRoute element={<Graph />} />} />
//       </Routes>
//     </Router>
//   );
// };

// export default App;

