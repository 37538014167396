import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Profile.css';
import { jwtDecode } from 'jwt-decode';
import { server } from '../../api/apiservice';


const Profile = () => {
    const [employeeId, setEmployeeId] = useState('');
    const [employeeRatings, setEmployeeRatings] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log('JWT Token:', token);
        if (token) {
            const decodedToken = jwtDecode(token);
            console.log('Decoded Token:', decodedToken);
            setEmployeeId(decodedToken.id); // Set employeeId from decoded token
        } else {
            console.error('No token found in localStorage');
        }
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (employeeId) {
            // Fetch employee ratings along with the form details
            axios.get(`${server}manager-ratings/employee-ratings1/${employeeId}`, {
                headers: {
                    Authorization: `${token}`
                }
            })
                .then(response => {
                    // console.log('response.data', response.data);
                    setEmployeeRatings(response.data.employeeRatings);
                })
                .catch(error => {
                    console.error('Error fetching ratings:', error);
                });
        }
    }, [employeeId]);

    return (
        <div className="profile">
            <h3>Your Rating History</h3>
            {Array.isArray(employeeRatings) && employeeRatings.length > 0 ? (
                employeeRatings.map((rating, index) => (
                    <div key={index} className="rating-history">
                        {rating.formId ? (
                            <>
                                <h3>Form: {rating.formId.title}</h3>
                                {Array.isArray(rating.ratings) && rating.ratings.map((item, i) => (
                                    <div key={i} className="rating-item">
                                        <p><strong>Question:</strong> {rating.formId.questions[item.questionIndex]?.label}</p>
                                        <p><strong>Your Response:</strong> {item.response}</p>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <p>Form details are not available.</p>
                        )}
                    </div>
                ))
            ) : (
                <p>No ratings available.</p>
            )}
        </div>
    );
};

export default Profile;