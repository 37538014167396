import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Signup.css'
import { server } from '../../api/apiservice';


const Signup = ({ onLoginClick }) => {
  const [form, setForm] = useState({ name: '', email: '', password: '', role: '' });
  //adding
  const [errors, setErrors] = useState({ name: '', email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [managers, setManagers] = useState([]);  // Store the list of managers

  const navigate = useNavigate();

  const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });


console.log("mmmmmmmmmmmmma",managers);


  // adding validation part
  const validate = () => {
    let valid = true;
    let errors = {};

    // Validate name
    if (!form.name) {
      errors.name = 'Name is required';
      valid = false;
    }

    // Validate email
    if (!form.email.includes('@sumerudigital.com')) {
      errors.email = 'Email must be in the format name@sumerudigital.com';
      valid = false;
    }

    // Validate password
    if (form.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
      valid = false;
    }

    setErrors(errors);
    return valid;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // applying validation condition
    if (validate()) {
      try {
        const { data } = await axios.post(`${server}auth/register`, form);
        localStorage.setItem('token', data.token);
        // Success toast when registration is successful
        toast.success('Registration successful!', {
          position: "top-right",
          autoClose: 3000, // Toast will auto close in 3 seconds
        });
        setTimeout(() => {
          navigate('/employee-dashboard');
        }, 2000);
      } catch (error) {
        console.error(error);
        // Error toast when registration fails
        toast.error('Registration failed. Please check your details and try again.', {
          position: "top-right",
          autoClose: 2000, // Toast will auto close in 3 seconds
        });
      }
    }
  };

  useEffect(() => {
    // Fetch managers when the component mounts
    const fetchManagers = async () => {
      try {
        const { data } = await axios.get(`${server}userRoutes/managers`);
        setManagers(data);  // Set the fetched managers
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, []);

  return (
    <div class="image">
      <div className="signup-container">
        <div className="signup-box">
          <h1>Signup</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={form.name}
                onChange={handleChange}
                required
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="input-groupes">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                autoComplete="off"
                placeholder="name@sumerudigital.com"
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="input-group password-group">
              <label htmlFor="password">Password</label>
              <div className='password-container'>
                <input
                  type={showPassword ? 'text' : 'password'} // Toggle between text and password
                  id="password"
                  name="password"
                  value={form.password}
                  onChange={handleChange}
                  autoComplete="new-password"
                  placeholder="At least 6 characters"
                  required
                />
                <span className="password-toggle" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {errors.password && <p className="error">{errors.password}</p>}
            </div>
            <div className="input-group">
              <label htmlFor="role">Role</label>
              <select
                id="role"
                name="role"
                value={form.role}
                onChange={handleChange}
                required
              >
                <option value="">Select Role</option>
                <option value="Employee">Employee</option>
              </select>
            </div>

            {/* Manager selection dropdown */}
            <div className="input-group">
              <label htmlFor="manager_id">Your Manager</label>
              <select
                id="manager_id"
                name="manager_id"
                value={form.manager_id}
                onChange={handleChange}
                required
              >
                <option value="">Select Manager</option>
                {managers.map((manager) => (
                  <option key={manager._id} value={manager._id}>
                    {manager.name}
                  </option>
                ))}
              </select>
            </div>

            <button type="submit" className="signup-button">Signup</button>
          </form>
          {/* Signup link */}
          <p>
            Already have an account? <button onClick={onLoginClick} style={{ color: 'blue', background: 'none', border: 'none', cursor: 'pointer' }}>Login</button>
          </p>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Signup;