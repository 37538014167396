import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LeaveHistory.css';
import { server } from '../api/apiservice';

const LeaveHistory = ({ isOpen, toggLeaveHistory }) => {
    const [leaveHistory, setLeaveHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchLeaveHistory = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${server}leave/myLeaves`, {
                    headers: { Authorization: `${token}` },
                });
                console.log('User Leave History Response:', response.data);
                setLeaveHistory(response.data);
            } catch (error) {
                console.error('Error fetching leave history:', error);
                setMessage('Failed to fetch leave history. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchLeaveHistory();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const container = document.querySelector('.apply-leave-container');
            if (container && !container.contains(event.target)) {
                toggLeaveHistory();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <>
            <div className="Apply-button-container">
                <button className="Apply-button-click" onClick={toggLeaveHistory} style={{ border: "none" }}>
                    {/* Add icon or text here if needed */}
                </button>
            </div>

            {isOpen && (
                <div className='history-overlay'>
                <div className="leave-history-container apply-leave-container"> {/* Ensure this class is applied here */}
                    <h2>My Leave History</h2>
                    {loading ? (
                        <p className="loading-message">Loading...</p>
                    ) : leaveHistory.length ? (
                        <table className="leave-history-table">
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    <th>Leave Type</th>
                                    <th>Duration</th>
                                    <th>Status</th>
                                    <th>Applied Date</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveHistory.map((leave) => (
                                    <tr key={leave._id}>
                                        <td>{leave.employee?.name || 'N/A'}</td>
                                        <td>{leave.leaveType}</td>
                                        <td>{`${formatDate(leave.leaveStartDate)} - ${formatDate(leave.leaveEndDate)}`}</td>
                                        <td>{leave.status}</td>
                                        <td>{formatDate(leave.appliedDate)}</td>
                                        <td dangerouslySetInnerHTML={{ __html: leave.comments || 'No comments' }}></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>No leave history available.</p>
                    )}
                    {message && <p className="message">{message}</p>}
                </div>
                </div>
            )}
        </>
    );
};

export default LeaveHistory;
