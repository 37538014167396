
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ProfileUpdate.css';
import { server } from '../../api/apiservice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProfileUpdate = () => {
    const [userData, setUserData] = useState({
        name: '',
        role: '',
        dateOfBirth: '',
        joiningDate: '',
        phoneNo: '',
        gender: '',
        maritalStatus: '',
        degree: '',
        institution: '',
        percentage: '',
        specialization: '',
        companyName: '',
        roleCompany: '',
        cstartdate: '',
        cenddate: '',
        skills: '',
        yourmanager: '',
    });

    const [profileImage, setProfileImage] = useState(null);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const [isOpen, setIsOpen] = useState(false);
    const notificationRef = useRef(null);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setMessage('No token found, please log in again.');
                    setMessageType('error');
                    return;
                }

                const { data } = await axios.get(`${server}auth/dashboard`, {
                    headers: { Authorization: `${token}` }
                });

                // Assuming the API response has the necessary fields
                setUserData({
                    name: data.name,
                    roleEmp: data.roleEmp,
                    yourmanager: data.yourmanager,
                    dateOfBirth: formatDate(data.dateOfBirth),
                    joiningDate: formatDate(data.joiningDate),
                    phoneNo: data.phoneNo || '',
                    gender: data.gender || '',
                    maritalStatus: data.maritalStatus || '',
                    degree: data.degree || '',
                    institution: data.institution || '',
                    percentage: data.percentage || '',
                    specialization: data.specialization || '',
                    companyName: data.companyName || '',
                    roleCompany: data.roleCompany || '',
                    cstartdate: data.cstartdate || '',
                    cenddate: data.cenddate || '',
                    skills: data.skills || '',
                });
            } catch (error) {
                setMessage('Error fetching user data: ' + (error.response ? error.response.data.message : 'Network Error'));
                setMessageType('error');
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage('No token found, please log in again.');
                setMessageType('error');
                return;
            }

            const formData = new FormData();
            formData.append('name', userData.name);
            formData.append('yourmanager', userData.yourmanager);
            formData.append('roleEmp', userData.roleEmp);
            formData.append('dateOfBirth', new Date(userData.dateOfBirth).toISOString());
            formData.append('joiningDate', new Date(userData.joiningDate).toISOString());
            formData.append('phoneNo', userData.phoneNo);
            formData.append('gender', userData.gender);
            formData.append('maritalStatus', userData.maritalStatus);
            formData.append('degree', userData.degree);
            formData.append('institution', userData.institution);
            formData.append('percentage', userData.percentage);
            formData.append('specialization', userData.specialization);
            formData.append('companyName', userData.companyName);
            formData.append('roleCompany', userData.roleCompany);
            formData.append('cstartdate', userData.cstartdate);
            formData.append('cenddate', userData.cenddate);
            formData.append('skills', userData.skills);

            if (profileImage) {
                formData.append('profileImage', profileImage);
            }

            const { data } = await axios.put(`${server}auth/updateProfile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${token}`
                }
            });

            setMessage('Profile updated successfully!');
            setMessageType('success');
            setUserData({
                name: '',
                roleEmp: '',
                dateOfBirth: '',
                joiningDate: '',
                phoneNo: '',
                gender: '',
                maritalStatus: '',
                degree: '',
                institution: '',
                percentage: '',
                specialization: '',
                companyName: '',
                roleCompany: '',
                cstartdate: '',
                cenddate: '',
                skills: '',
                yourmanager: '',
            });
            setProfileImage(null);
            setTimeout(() => setMessage(''), 3000);
            // setIsOpen(false);
            toast.success("Updated successful!", {
                position: "top-right",
                autoClose: 2000,
            });

        } catch (error) {
            toast.error("Failed", {
                position: "top-right",
                autoClose: 2000,
            });
            setMessage('Error updating profile: ' + (error.response ? error.response.data.message : 'Network Error'));
            setMessageType('error');
            console.error('Error updating profile:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handlePhoneChange = (event) => {
        let phoneNumber = event.target.value;
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '').slice(0, 10);
        setUserData({
            ...userData,
            phoneNo: '+91' + phoneNumber
        });
    };

    return (
        <>


            <div className="update-prof-container">
                <h2>Update Profile</h2>
                {message && <p className={`message ${messageType}`}>{message}</p>}
                {isLoading && <p>Loading...</p>}
                <form onSubmit={handleSubmit}>
                    <div className="section">
                        <h3>Personal Details</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    Name:
                                    <input
                                        type="text"
                                        name="name"
                                        value={userData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Company Role:
                                    <input
                                        type="text"
                                        name="roleEmp"
                                        value={userData.roleEmp}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>

                            <div className="form-group">
                                <label>
                                    Your Manager:
                                    <select
                                        type="text"
                                        name="yourmanager"
                                        value={userData.yourmanager}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Manager</option>
                                        <option value="Chinmay">Chinmay</option>
                                        <option value="Niharika">Niharika</option>
                                    </select>
                                </label>
                            </div>

                            <div className="form-group">
                                <label>
                                    Date of Birth:
                                    <input
                                        type="date"
                                        name="dateOfBirth"
                                        value={userData.dateOfBirth}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Joining Date:
                                    <input
                                        type="date"
                                        name="joiningDate"
                                        value={userData.joiningDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Phone No:
                                    <input
                                        type="text"
                                        name="phoneNo"
                                        value={userData.phoneNo.slice(3)}  
                                        onChange={handlePhoneChange}
                                        required
                                        placeholder="XXXXXXXXXX" 
                                        pattern="\d{10}"
                                        maxLength={10} 
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Gender:
                                    <select
                                        name="gender"
                                        value={userData.gender}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Transgender">Transgender</option>
                                    </select>
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Marital Status:
                                    <select
                                        name="maritalStatus"
                                        value={userData.maritalStatus}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Marital Status</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Widowed">Widowed</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <h3>Education Details</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    Degree:
                                    <input
                                        type="text"
                                        name="degree"
                                        value={userData.degree}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Institution:
                                    <input
                                        type="text"
                                        name="institution"
                                        value={userData.institution}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Percentage:
                                    <input
                                        type="text"
                                        name="percentage"
                                        value={userData.percentage}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Specialization:
                                    <input
                                        type="text"
                                        name="specialization"
                                        value={userData.specialization}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="section">
                        <h3>Experience Details</h3>
                        <div className="form-row">
                            <div className="form-group">
                                <label>
                                    Company Name:
                                    <input
                                        type="text"
                                        name="companyName"
                                        value={userData.companyName}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Role in Company:
                                    <input
                                        type="text"
                                        name="roleCompany"
                                        value={userData.roleCompany}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Company Start Date:
                                    <input
                                        type="date"
                                        name="cstartdate"
                                        value={userData.cstartdate}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Company End Date:
                                    <input
                                        type="date"
                                        name="cenddate"
                                        value={userData.cenddate}
                                        onChange={handleChange}
                                    // required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Skills:
                                    <input
                                        type="text"
                                        name="skills"
                                        value={userData.skills}
                                        onChange={handleChange}
                                    // required
                                    />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>
                            Profile Image:
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                            />
                        </label>
                    </div>

                    <button className='profileclick' style={{ width: "200px", textDecoration: "white" }} type="submit" disabled={isLoading}>
                        {isLoading ? 'Updating...' : 'Update Profile'}
                    </button>
                </form>
                <ToastContainer />
            </div>


        </>
    );
};

export default ProfileUpdate;
