import React from 'react';
import './HolidayOverlay.css';

const HolidayOverlay = ({ holidays, isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="holiday-overlay">
      <div className="holiday-overlay-content">
        <h2>Holidays</h2>
        <button className="close-holiday-overlay-btn" onClick={onClose}>
          Close
        </button>
        <div className="holidays-list">
          {holidays.map((holiday, index) => (
            <div key={index} className="holiday-item">
              <img src={holiday.imageUrl} alt={holiday.name} className="holiday-image" />
              <div className="holiday-details">
                <h4>{holiday.name}</h4>
                <p>{holiday.date}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
     </div>
  );
};

export default HolidayOverlay;

