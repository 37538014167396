import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ApproveLeave.css';
import { server } from '../api/apiservice';

const ApproveLeave = () => {
  const { token } = useParams();
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const tokenn = localStorage.getItem('token');

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get(`${server}leave/employees`, {
          headers: { Authorization: `${tokenn}` },
        });
        setEmployees(response.data);
      } catch (error) {
        setMessage('Failed to fetch employees. Please try again later.');
      }
    };
    fetchEmployees();
  }, [tokenn]);

  useEffect(() => {
    if (selectedEmployee) {
      const fetchLeaveRequests = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${server}leave/requests?employeeId=${selectedEmployee._id}`, {
            headers: { Authorization: `${tokenn}` },
          });
          setLeaveRequests(response.data);
          setMessage('');
        } catch (error) {
          setMessage('Failed to fetch leave requests. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
      fetchLeaveRequests();
    } else {
      setLeaveRequests([]);  // Clear requests if no employee is selected
      setSelectedRequest(null);  // Clear selected request details
    }
  }, [selectedEmployee, tokenn]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(prevEmployee => (prevEmployee && prevEmployee._id === employee._id ? null : employee));
  };

  const handleDateSelect = (request) => {
    if (request.status === 'Filled' || request.status === 'Approved' || request.status === 'Rejected') {
      setMessage('This leave request has already been processed and cannot be modified.');
    } else {
      setMessage('');
      setSelectedRequest(prevRequest => (prevRequest && prevRequest._id === request._id ? null : request));
    }
  };
  const handleClose = () => {
    setSelectedRequest(null);
    setMessage('');
  };

  const handleApproval = async (status) => {
    if (selectedRequest) {
      try {
        await axios.put(`${server}leave/approveLeave/${selectedRequest._id}`, {
          status: status ? 'Approved' : 'Rejected', comments
        }, { headers: { Authorization: `${tokenn}` } });

        setMessage(`Leave request has been ${status ? 'approved' : 'rejected'}!`);
        setSelectedRequest(null);  // Hide after action
        setLeaveRequests(leaveRequests.map(req => req._id === selectedRequest._id ? { ...req, status: 'Filled' } : req)); // Update status
      } catch (error) {
        setMessage('Failed to update leave request. Please try again.');
      }
    }
  };

  return (
    <div className="approve-leave-container">
      <div className="employee-list">
        <h2>Employees Leave Requests</h2>
        <p>Click an employee's name to view their leave requests.</p>
        <ul>
          {employees.map(employee => (
            <li key={employee._id} onClick={() => handleEmployeeSelect(employee)}>
              {/* {employee.name} (Click to view requests) */}
              <strong>{employee.name}</strong>
              <span className="view-requests-link">(Click to view requests)</span>
            </li>
          ))}
        </ul>
      </div>

      {loading ? <p></p> : (
        <div className="leave-requests">
          <h2>Leave Requests</h2>
          <p>Click a request date to view or close its details.</p>
          {leaveRequests.length > 0 ? (
            <ul>
              {leaveRequests.map(request => (
                <li key={request._id} onClick={() => handleDateSelect(request)} className="leave-request-item">
                  <span>{formatDate(request.leaveStartDate)}</span>
                  <span className={`status-label status-${request.status.toLowerCase()}`}>
                    {request.status}
                  </span>
                </li>
              ))}
            </ul>
          ) : <p>No leave requests found.</p>}
        </div>
      )}

      {selectedRequest && (
        <div className="leave-details-card">
          <h2>Leave Request Details</h2>
          <button className="close-button" onClick={handleClose}>Close</button>
          <p><strong>Employee:</strong> {selectedRequest.employee.name}</p>
          <p><strong>Type:</strong> {selectedRequest.leaveType}</p>
          <p><strong>Duration:</strong> {formatDate(selectedRequest.leaveStartDate)} to {formatDate(selectedRequest.leaveEndDate)}</p>
          <p><strong>Reason:</strong> {selectedRequest.reason}</p>
          <div className="approval-section">
            <textarea
              placeholder="Enter comments (optional)"
              value={comments}
              onChange={e => setComments(e.target.value)}
            />
            <div className="approval-buttons">
              <button className="approve-button" onClick={() => handleApproval(true)}>Approve</button>
              <button className="reject-button" onClick={() => handleApproval(false)}>Reject</button>
            </div>
          </div>
        </div>
      )}

      <p className="message">{message}</p>
    </div>
  );
};

export default ApproveLeave;
