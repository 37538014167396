import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import './CeoDashboard.css';
import { useNavigate } from 'react-router-dom';
import SidebarAll from '../sidebar'; // Correct import for SidebarAll component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { server } from '../../api/apiservice';
import HolidayOverlay from '../Calender/HolidayOverlay';
import NavigationBar from '../NavBar/NavigationBar';

const EmployeeProfile = ({ name, email, average }) => {
  return (
    <div className="employee-profile">
      <div className="profile-pic">
        <img src={`https://via.placeholder.com/150`} alt="Profile" />
      </div>
      <div className="profile-info">
        <h4>{name}</h4>
        <p>{email}</p>
        <p>Average Rating: {average}</p>
      </div>
    </div>
  );
};

const CEODashboard = () => {
  const [employeeRatings, setEmployeeRatings] = useState([]);
  const [managerRatings, setManagerRatings] = useState([]);
  const [ceoRatings, setCeoRatings] = useState([]);
  const [forms, setForms] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [expandedEmployeeIds, setExpandedEmployeeIds] = useState([]);
  const [answers, setAnswers] = useState({});
  const [employeeId, setEmployeeId] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [averages, setAverages] = useState({});
  const [highestRatedEmployee, setHighestRatedEmployee] = useState(null);
  const [feedbacks, setFeedbacks] = useState({}); // State for managing feedback
  const navigate = useNavigate();
  const [showFormsSection, setShowFormsSection] = useState(false);
  const [users, setUsers] = useState([]);
  // const [showUsers, setShowUsers] = useState(false);
  const [userRole, setUserRole] = useState('CEO');

  const [showUsers, setShowUsers] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [error, setError] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  const [activeSection, setActiveSection] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [managerSelfRatings, setManagerSelfRatings] = useState([]); 
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [manageropenmodel, setmanageropenmodel] = useState(false);
  

  const [holidays, setHolidays] = useState([
    { date: '26 Jan', name: 'Republic Day ', imageUrl: 'republic.jpg' },
    { date: '8 Mar', name: 'Maha Shivaratri', imageUrl: 'shivratri.avif' },
    { date: '25 Mar', name: 'Holi', imageUrl: 'holi.avif' },
    { date: '9 Apr', name: 'Ugadi', imageUrl: 'ugadi.webp' },
    { date: '1 May', name: 'Labour Day ', imageUrl: 'labour.jpeg' },
    { date: '15 Aug', name: 'Independence Day', imageUrl: 'indi.jpg' },
    { date: '7 Sep', name: 'Ganesh Chaturthi', imageUrl: 'ganesh.jpeg' },
    { date: '2 Oct', name: 'Gandhi Jayanti', imageUrl: 'gandhi.jpg' },
    { date: '11 Oct', name: 'Maha Navami', imageUrl: 'navami.avif' },
    { date: '12 Oct', name: 'Dusshera', imageUrl: 'dus.jpeg' },
    { date: '1 Nov', name: 'Kannada Rajyothsava / Diwali', imageUrl: 'kan.jpeg' },
    { date: '5 Nov', name: 'Balipadyami, Deepavali', imageUrl: 'diwali.jpeg' },
    { date: '25 Dec', name: 'Christmas', imageUrl: 'christmas.jpg' },
  ]);
   
  
  const getUserNameById = (employeeId) => {
    const user = users.find(user => user._id === employeeId); 
    // console.log("Found user:", user);
    return user ? user.name : "Employee";
  };
  


  const getOrdinalSuffix = (index) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = index % 10;
    const suffix = remainder > 0 && remainder < 4 && ![11, 12, 13].includes(index) ? suffixes[remainder - 1] : "th";
    return `${index}${suffix}`;
  };

  const [isHolidayOverlayVisible, setIsHolidayOverlayVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleHolidaysClick = () => {
    setIsHolidayOverlayVisible(true);
  };

  const closeHolidayOverlay = () => {
    setIsHolidayOverlayVisible(false);
  };


  const handleEmployeeExpansionAndModal = (employeeId) => {
    toggleEmployeeExpansion(employeeId); // Toggle the employee expansion
    // handleExpandedClick(); // Open/close the modal
    setOpenModal(true);
  };
  const closeExpandedClick = () => {
    setOpenModal(false); // Close the modal
    setExpandedEmployeeIds([]); // Reset the expanded employee list
  };
  const handlemanagerRatingsExpansionAndModal = (employeeId) => {
    toggleEmployeeExpansion(employeeId); // Toggle the employee expansion
    // handleExpandedClick(); // Open/close the modal
    setmanageropenmodel(true);
  };
  const closemanagerRatingsExpandedClick = () => {
    setmanageropenmodel(false); // Close the modal
    setExpandedEmployeeIds([]); // Reset the expanded employee list
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen); // toggles the filter open/close
  };






  // Check authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [navigate]);


  useEffect(() => {
    if (userRole === 'CEO') {
      setShowFormsSection(true);
    }
  }, [userRole]);


  const fetchForms = async (filter) => {
    try {
      setLoading(true);
      const response = await axios.get(`${server}admin/forms-by-month`, {
        params: { filter }
      });
      console.log("FFFF", response);

      setForms(response.data.data); // Update forms with the API response
      setError("");
    } catch (err) {
      setForms([]);
      setError(err.response?.data?.message || "Error fetching forms");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedFilter) {
      fetchForms(selectedFilter);
    }
  }, [selectedFilter]);


  const fetchUsers = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.get(`${server}userRoutes/users`, {
        headers: { Authorization: ` ${token}` }, 
      });
      setUsers(response.data);
  
      // console.log("Fetched Users:", response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  
  const handleShowUsers = () => {
    setShowUsers(true);
    setShowFormsSection(false);
    setActiveSection(false)
    if (!showUsers) {
      fetchUsers();
    }
  };

  const onFormSelect = () => {
    setShowFormsSection(true);
    setShowUsers(false);
    setActiveSection(false)

  };

  // const showFormsSection = () => {
  //   setSelectedFormId(null); // or any logic that ensures the forms section is displayed
  // };


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.id);
      setEmployeeName(decodedToken.name);
    }

    axios.get(`${server}admin/forms`)
      .then(response => {
        setForms(response.data);
      })
      .catch(error => {
        console.error('Error fetching forms:', error);
      });
  }, []);

  // const handleSignout = () => {
  //   localStorage.removeItem('token');
  //   navigate('/');
  // };
  const handleSignout = () => {


    toast(<CustomLogoutToast />);
  };

  const CustomLogoutToast = ({ closeToast }) => (
    <div >
      <p style={{ color: "white" }}>Are you sure you want to Sign out?</p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => {
            localStorage.removeItem("token");
            toast.success("Successfully logged out!");
            navigate("/"); // navigate after logging out
            closeToast(); // close the toast
          }}
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Yes
        </button>
        <button onClick={closeToast} style={{ borderRadius: "20px", cursor: "pointer" }}>Cancel</button>
      </div>  
    </div>
  );


  useEffect(() => {
    fetchUsers()
    const token = localStorage.getItem('token');
    const name = searchName;  // Assume this comes from a search input, could be passed as a state value

    axios.get(`${server}ceo-ratings/employee-ratings`, {
      headers: { Authorization: `${token}` },
      params: { name },  // Passing name as a query param
    })
      .then(response => {
        const { employeeRatings = [], managerRatings = [], ceoRatings = [] } = response.data;

        // Check if employeeRatings array is valid
        setEmployeeRatings(employeeRatings);
        setManagerRatings(managerRatings);
        setCeoRatings(ceoRatings);
        console.log("ceoRatings", ceoRatings);
        console.log("managerRatings ", managerRatings);



        // Calculate averages, making sure objects exist and values are valid
        const calculatedAverages = employeeRatings.reduce((acc, rating) => {
          if (!rating || !rating.ratings) return acc;  // Check if rating exists

          const numericValues = rating.ratings.map(item => {
            if (typeof item.response === 'string') {
              const match = item.response.match(/\d+/);
              return match ? parseInt(match[0], 10) : 0;
            }
            return 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        // Similarly, calculate manager and CEO averages
        const calculatedManagerAverages = managerRatings.reduce((acc, rating) => {
          if (!rating || !rating.answers) return acc;

          const numericValues = rating.answers.map(item => {
            const match = item.response ? item.response.match(/\d+/) : null;
            return match ? parseInt(match[0], 10) : 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        const calculatedCeoAverages = ceoRatings.reduce((acc, rating) => {
          if (!rating || !rating.answers) return acc;

          const numericValues = rating.answers.map(item => {
            const match = item.response ? item.response.match(/\d+/) : null;
            return match ? parseInt(match[0], 10) : 0;
          });

          const average = numericValues.length > 0
            ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
            : 0;

          if (rating.employeeId && rating.employeeId._id) {
            acc[rating.employeeId._id] = average.toFixed(2);
          }
          return acc;
        }, {});

        setAverages({
          employees: calculatedAverages,
          managers: calculatedManagerAverages,
          ceos: calculatedCeoAverages,
        });
      })
      .catch(error => {
        console.error('Error fetching ratings:', error);
      });
  }, [searchName]);  // You can trigger this useEffect based on search input change

  const handleSearchChange = (e) => {
    setSearchName(e.target.value); // Update search input value
  };

  const saveOverallAverage = async (employeeId, employeeName, overallAverage) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${server}userRoutes/save-overall-average`,
        { employeeId, employeeName, overallAverage },
        { headers: { Authorization: `${token}` } }
      );
      console.log('Overall average saved successfully');
    } catch (error) {
      console.error('Error saving overall average:', error);
    }
  };


  const calculateOverallAverage = (employeeId, employeeName) => {
    const employeeAvg = parseFloat(averages.employees?.[employeeId] || 0);
    const managerAvg = parseFloat(averages.managers?.[employeeId] || 0);
    const ceoAvg = parseFloat(averages.ceos?.[employeeId] || 0);

    const overallAverage = ((employeeAvg + managerAvg + ceoAvg) / 3).toFixed(2);

    saveOverallAverage(employeeId, employeeName, overallAverage);

    console.log("Calculating average for", employeeName, employeeId);


    return overallAverage;
  };




  const toggleFormExpansion = (formId) => {
    setSelectedFormId(prevState => prevState === formId ? null : formId);
    setExpandedEmployeeIds([]);
  };

  const toggleEmployeeExpansion = (employeeId) => {
    setExpandedEmployeeIds(prevState =>
      prevState.includes(employeeId)
        ? prevState.filter(id => id !== employeeId)
        : [...prevState, employeeId]
    );
  };
  

  const handleRatingChange = (question, value, employeeId) => {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [employeeId]: {
        ...prevAnswers[employeeId],
        [question]: value,
      }
    }));
  };

  const handleFeedbackChange = (employeeId, value) => {
    setFeedbacks(prevFeedbacks => ({
      ...prevFeedbacks,
      [employeeId]: value
    }));
  };

  const handleSubmit = async (selectedEmployeeId) => {
    const token = localStorage.getItem('token');
    try {
      const selectedForm = forms.find(form => form._id === selectedFormId);
      const feedback = feedbacks[selectedEmployeeId] || '';

      // Check if there's feedback but no ratings
      const isOnlyFeedback = !answers[selectedEmployeeId] && feedback;

      if (isOnlyFeedback) {
        const defaultAnswer = {
          questionIndex: 0,
          response: "Feedback Only"
        };

        const feedbackData = {
          employeeId: selectedEmployeeId,
          formId: selectedFormId,
          feedback: feedback,
          answers: [defaultAnswer]
        };

        try {
          const response = await axios.post(
            `${server}ceo-ratings/submit-rating-feedback-ceo`, 
            feedbackData,
            {
              headers: { Authorization: `${token}` },
            }
          );

          setCeoRatings(prevRatings => [...prevRatings, response.data.ceoRating]);
          
          toast.success('Feedback submitted successfully!', {
            position: "top-right",
            autoClose: 2000,
          });

          // Reset form
          setSelectedFormId(null);
          setFeedbacks({});

          setTimeout(() => {
            window.location.reload();
          }, 1000);

        } catch (error) {
          if (error.response && error.response.status === 403) {
            toast.warning('You have already submitted feedback for this employee.', {
              position: "top-right",
              autoClose: 3000,
            });
          } else {
            toast.error('Submission failed', {
              position: "top-right",
              autoClose: 2000,
            });
          }
        }
        return;
      }

      // For ratings submission
      try {
        const ceoRatings = selectedForm.questions.map((question, index) => ({
          questionIndex: index,
          response: answers[selectedEmployeeId]?.[question.label] || ''
        }));

        // Validation for ratings
        const allFieldsFilled = ceoRatings.every(rating => rating.response !== '');
        if (!allFieldsFilled) {
          toast.error('Please fill all required fields before submitting.', {
            position: "top-right",
            autoClose: 2000,
          });
          return;
        }

        const ceoRatingData = {
          employeeId: selectedEmployeeId,
          formId: selectedFormId,
          answers: ceoRatings,
          feedback: feedback
        };

        const response = await axios.post(
          `${server}ceo-ratings/submit-rating-feedback-ceo`, 
          ceoRatingData,
          {
            headers: { Authorization: `${token}` },
          }
        );

        setCeoRatings(prevRatings => [...prevRatings, response.data.ceoRating]);

        toast.success('Rating and feedback submitted successfully!', {
          position: "top-right",
          autoClose: 2000,
        });

        // Reset form
        setSelectedFormId(null);
        setAnswers({});
        setFeedbacks({});

        setTimeout(() => {
          window.location.reload();
        }, 1000);

      } catch (error) {
        if (error.response && error.response.status === 403) {
          toast.warning('You have already submitted ratings for this employee.', {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.error('Submission failed', {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }

    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error('An unexpected error occurred', {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  // I am adding Fetch top 3 highest rated employees
  useEffect(() => {
    if (activeSection === 'topRated') {
      fetchTopThreeUsers();
    }
  }, [activeSection]);

  const fetchTopThreeUsers = async () => {
    try {
      const response = await axios.get(`${server}userRoutes/topThreeRatings`);
      setTopThreeUsers(response.data.ratings);
    } catch (error) {
      console.error('Error fetching top 3 rated users:', error);
    }
  };

  const handleTopRatedClick = () => {
    setActiveSection('topRated');

    setShowFormsSection(false);
    setShowUsers(false);
  };

  const getRowColor = (employeeRating, managerRating, ceoRating) => {
    console.log('Ratings:', { employeeRating, managerRating, ceoRating });

    // Helper function to safely parse numeric rating
    const parseRating = (rating) => {
      if (typeof rating !== 'string') return 0;
      const match = rating.match(/\d+/);
      return match ? parseInt(match[0], 10) : 0;
    };

    const numericEmployeeRating = parseRating(employeeRating);
    const numericManagerRating = parseRating(managerRating);
    const numericCeoRating = parseRating(ceoRating);

    console.log('Numeric Ratings:', { numericEmployeeRating, numericManagerRating, numericCeoRating });

    let color;
    if (ceoRating && ceoRating !== 'N/A') {
      // CEO has rated
      if (numericEmployeeRating > numericManagerRating && numericEmployeeRating > numericCeoRating) {
        color = '#f64747';
      } else if (numericEmployeeRating < numericManagerRating || numericEmployeeRating < numericCeoRating) {
        color = '#00b5cc';
      } else {
        color = '#50C878';
      }
    } else {
      // CEO hasn't rated yet
      if (numericEmployeeRating > numericManagerRating) {
        color = '#f64747';
      } else if (numericEmployeeRating < numericManagerRating) {
        color = '#00b5cc';
      } else {
        color = '#50C878';
      }
    }

    console.log('Calculated color:', color);
    return color;
  };


  useEffect(() => {
    const fetchManagerSelfRatings = async () => {
      const token = localStorage.getItem("token");

      try {
        // Make an API request to get self-ratings
        const response = await axios.get(`${server}ceo-ratings/managerself-rating`, {
          headers: {
            Authorization: `${token}` 
          }
        });
        
        console.log("1111111111111111111",response.data.allSelfRatings);
        console.log("Fetched Manager Self Ratings Answers:", response.data.allSelfRatings[0].answers);

        if (response.data && Array.isArray(response.data.allSelfRatings)) {
          setManagerSelfRatings(response.data.allSelfRatings);
        } else {
          setManagerSelfRatings([]); 
        }
        setLoading(false);
        
      } catch (error) {
        console.error(
          "Error fetching manager self-ratings:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchManagerSelfRatings();
  }, []);

  const calculateRatingPercentage = (ratings) => {
    if (!Array.isArray(ratings) || ratings.length === 0) {
      return "0.0"; 
    }  
    // Safely calculate totalScore using reduce
    const totalScore = ratings.reduce((sum, rating) => {
      if (rating && rating.response && typeof rating.response === 'string') {
        const score = ratings.reduce((sum, rating) => sum + parseInt(rating.response.split(' ')[0], 10), 0);
        {console.log("22222222222222",score)}
        if (!isNaN(score)) {
          return sum + score; 
        
        }
      }
      return sum; 
      
    }, 0);
  
    const maxScore = ratings.length * 5; 
    const percentage = (totalScore / maxScore) * 10;
  
    return percentage.toFixed(2); 
  };
  


  // Step 1: Calculate Manager Ratings Averages
const calculatedManagerAverages = managerSelfRatings.reduce((acc, rating) => {
  if (!rating || !Array.isArray(rating.answers)) {
    console.warn('Invalid rating or answers:', rating);
    return acc; // Skip invalid ratings
  }

  // console.log("Processing Rating:", rating);

  const numericValues = rating.answers.map(item => {
    // console.log("Item response:", item.response); 
    if (item.response) {
      const match = item.response.match(/\d+/);
      if (match) {
        return parseInt(match[0], 10);
      }
    }
    return 0; // Return 0 for invalid or non-numeric responses
  }).filter(value => value > 0); // Filter out any zero values

  // console.log("Numeric Values:", numericValues); 

  const average = numericValues.length > 0
    ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
    : 0;

  if (rating.employeeId) {
    acc[rating.employeeId] = average.toFixed(2); // Use the employeeId directly
  }
  return acc;
}, {});

// console.log("Calculated Manager Averages:", calculatedManagerAverages);


// console.log("Calculated Manager Averages mmmm:", calculatedManagerAverages);


// Step 2: Calculate CEO Ratings Averages
const calculatedCeoAverages = ceoRatings.reduce((acc, rating) => {
  if (!rating || !rating.answers) return acc;

  const numericValues = rating.answers.map(item => {
    const match = item.response ? item.response.match(/\d+/) : null;
    return match ? parseInt(match[0], 10) : 0;
  });

  const average = numericValues.length > 0
    ? numericValues.reduce((a, b) => a + b, 0) / numericValues.length
    : 0;

  if (rating.employeeId && rating.employeeId._id) {
    acc[rating.employeeId._id] = average.toFixed(2);
  }
  return acc;
}, {});

// Step 3: Calculate Manager Self-Rating by Combining Both CEO and Manager Averages
const combinedAverages = Object.keys(calculatedManagerAverages).reduce((acc, employeeId) => {
  const managerAverage = parseFloat(calculatedManagerAverages[employeeId] || 0);
  const ceoAverage = parseFloat(calculatedCeoAverages[employeeId] || 0);

  const finalAverage = !isNaN(ceoAverage) && ceoAverage !== 0
    ? ((managerAverage + ceoAverage) / 2).toFixed(2)
    : managerAverage.toFixed(2);

  acc[employeeId] = finalAverage;
  return acc;
}, {});

// console.log("Combined Averages:", combinedAverages);






  return (
    <>
    <NavigationBar />
    <div className="ceo-dashboard">
      
      <SidebarAll onFormSelect={onFormSelect} onSignout={handleSignout} onShowUsers={handleShowUsers} onTopRated={handleTopRatedClick} onHolidays={handleHolidaysClick} userRole={userRole} />
      
      {/* <h1>CEO DASHBOARD</h1> */}
      <div className="dashboard-content">

        {showFormsSection && (
          <div className="forms-section" style={{ margin: "100px 0px 0px 120px" }}>
            <div className="form-selection-container">
              <div className="form-selection-header">
                <h2>Select a Form to View Employees</h2>
                <div className="filter-container">
                  {isFilterOpen && (
                    <div className='filter-button'>
                      <div className="filter-button-group">
                        <button
                          className={`filter-button-item ${selectedFilter === "this-month" ? "active" : ""}`}
                          onClick={() => setSelectedFilter("this-month")}
                        >
                          This Month
                        </button>
                        <button
                          className={`filter-button-item ${selectedFilter === "past-3-months" ? "active" : ""}`}
                          onClick={() => setSelectedFilter("past-3-months")}
                        >
                          Past 3 Months
                        </button>
                        <button
                          className={`filter-button-item ${selectedFilter === "past-6-months" ? "active" : ""}`}
                          onClick={() => setSelectedFilter("past-6-months")}
                        >
                          Past 6 Months
                        </button>
                        <button
                          className={`filter-button-item ${selectedFilter === "current-year" ? "active" : ""}`}
                          onClick={() => setSelectedFilter("current-year")}
                        >
                          This Year
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="filter-icon" onClick={toggleFilter}>
                  <i className="fa-solid fa-filter"></i>
                </div>
              </div>
            </div>

            {forms.map(form => (
              <div key={form._id}>
                <div class="card-container">
                  <div onClick={() => toggleFormExpansion(form._id)} style={{ display: "flex", marginBottom: "10px", color: "white", background: "#2c3e50", width: "fit-content", padding: "27px 15px", borderRadius: "10px", cursor: "pointer" }}>
                    <div style={{ display: "flex" }}>
                      {form.title}
                    </div>
                  </div>
                </div>
                {selectedFormId === form._id && (
                  <div>
                    <div style={{ display: "flex", position: "relative" }}>
                      <div>
                        <h3>Employees who have rated themselves:</h3>
                      </div>
                      <div className='searchInput' style={{ width: "40%", marginTop: "5px", position: "absolute", right: "0" }}>
                        <input type="text" placeholder='search by name' value={searchName} onChange={handleSearchChange} />
                        <i style={{ position: "absolute", top: "20px", right: "15px" }} class="fa-solid fa-magnifying-glass"></i>
                      </div>
                    </div>
                    {employeeRatings
                      .filter((rating) => rating?.formId?._id === form._id && rating?.employeeId?._id)
                      .length > 0 ? (
                      <div style={{ paddingBottom: "30px" }}>
                        <table className="rating-table" >
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Name</th>
                              <th>Overall Avg</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {employeeRatings
                              .filter((rating) => rating?.formId?._id === form._id && rating?.employeeId?._id)
                              .map((rating, index) => (
                                <tr key={rating.employeeId._id}>
                                  <td>{index + 1}</td>
                                  <td
                                    style={{ cursor: 'pointer', color: 'blue', textDecoration: 'none' }}
                                  // onClick={() => toggleEmployeeExpansion(rating.employeeId._id)}
                                  >
                                    {rating?.employeeId?.name || 'Unknown Employee'}
                                  </td>
                                  <td>{calculateOverallAverage(rating?.employeeId?._id, rating?.employeeId?.name)}</td>
                                  <td>
                                    <button
                                      style={{ background: "#2c3e50" }}
                                      onClick={() => handleEmployeeExpansionAndModal(rating.employeeId._id)} // Combined action
                                    >
                                      open
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>

                        </table>

                        {openModal && (
                          <div className="expanded-modal-overlay" onClick={closeExpandedClick}>
                            <div className='expanded-table-modal' onClick={(e) => e.stopPropagation()}>
                              <button style={{ background: "red" }} onClick={closeExpandedClick}>
                                Close
                              </button>
                              {employeeRatings
                                .filter((rating) => expandedEmployeeIds.includes(rating?.employeeId?._id) && rating?.formId?._id === selectedFormId)
                                .map((rating) => (
                                  <div className="rating-details" key={rating?.employeeId?._id}>
                                    <h4>Ratings Summary for {rating?.employeeId?.name}</h4>
                                    <table className="ratings-table">
                                      <thead>
                                        <tr>
                                          <th>Employee's Ratings<br />Avg: {averages?.employees?.[rating?.employeeId?._id] || 'N/A'}</th>
                                          <th>Manager's Ratings<br />Avg: {averages?.managers?.[rating?.employeeId?._id] || 'N/A'}</th>
                                          <th>CEO's Ratings<br />Avg: {averages?.ceos?.[rating?.employeeId?._id] || 'N/A'}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {rating?.formId?.questions?.map((question, index) => {
                                          const employeeRating = rating?.ratings?.find(r => r.questionIndex === index)?.response || 'N/A';
                                          const managerRating = managerRatings
                                            .filter(mRating => mRating?.employeeId?._id === rating?.employeeId?._id && mRating?.formId?._id === form?._id)
                                            .map((mRating) => mRating?.answers?.find(a => a.questionIndex === index)?.response || 'N/A')[0];
                                          const ceoRating = ceoRatings
                                            .filter(cRating => cRating?.employeeId?._id === rating?.employeeId?._id && cRating?.formId?._id === form?._id)
                                            .map((cRating) => cRating?.answers?.find(a => a.questionIndex === index)?.response || 'N/A')[0];

                                          const rowColor = getRowColor(employeeRating, managerRating, ceoRating);

                                          return (
                                            <tr key={index}>
                                              <td style={{ backgroundColor: rowColor }}>
                                                <div className="question">{question.label}</div>
                                                <div className="response">{employeeRating}</div>
                                              </td>
                                              <td style={{ backgroundColor: rowColor }}>
                                                <div className="question">{question.label}</div>
                                                <div className="response">{managerRating}</div>
                                              </td>
                                              <td style={{ backgroundColor: rowColor }}>
                                                <div className="question">{question.label}</div>
                                                <div className="response">{ceoRating}</div>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>

                                    {!ceoRatings.some(cRating => cRating?.employeeId?._id === rating?.employeeId?._id && cRating?.formId?._id === form?._id) && (
                                      <>
                                        <h3>Rate The Employee:</h3>
                                        {forms
                                          .find(f => f?._id === selectedFormId)
                                          ?.questions?.map((question, index) => (
                                            <div key={index}>
                                              <label>{question?.label}</label>
                                              {question?.options && question?.options.length > 0 ? (
                                                <select style={{ width: "50%" }}
                                                  onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                                                  value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                                                >
                                                  <option value="">Select an option</option>
                                                  {question.options.map((option, i) => (
                                                    <option key={i} value={option}>{option}</option>
                                                  ))}
                                                </select>
                                              ) : (
                                                <input
                                                  type="text"
                                                  onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                                                  value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                                                />
                                              )}
                                            </div>
                                          ))
                                        }
                                      </>
                                    )}

                                    <h4>Give Feedback</h4>
                                    <textarea style={{ width: "50%" }}
                                      value={feedbacks?.[rating?.employeeId?._id] || ''}
                                      onChange={(e) => handleFeedbackChange(rating?.employeeId?._id, e.target.value)}
                                      placeholder="Enter your feedback here"
                                    />
                                    <div>
                                      <button style={{ backgroundColor: "#007bff", marginTop: "20px", width: "20%" }} onClick={() => handleSubmit(rating?.employeeId?._id)}>Submit</button>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p>No employees have rated themselves.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>


      {/* <button style={{backgroundColor:"#3498db"}} onClick={handleSignout}>Sign out</button> */}
      <div className='all-users-data1' style={{ margin: "100px 0px 0px 0px" }} >
        {showUsers && (
          <table style={{ marginLeft: '40px' }} className="users-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <React.Fragment key={user._id}>
                  <tr>
                    <td style={{ cursor: 'pointer', color: 'blue' }}>
                      {user.name}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {activeSection === 'topRated' && (
        <div className='ceomain'>
          <div className="overview-cards">
            <div className="card">
              <h1>Top Rated Employees</h1>
              {/* Top 3 Highest Rated Employees */}
              <div className="top-three">
                <div className="top-three-container">
                  {topThreeUsers.map((user, index) => (
                    <div
                      key={user._id}
                      className={`top-user-card top-user-${index + 1}`}
                    >
                      <img 
                      //src="profilemen.avif"
                      src={
                        user.profileImage
                          ? `${server}${user.profileImage}`
                          : "profilemen.avif"
                      } 
                      alt={user.employeeName} className="top-user-avatar" />
                    
                      <div className='top-user-details'>
                        <p>{user.employeeName}</p>
                        <p><b>{getOrdinalSuffix(index + 1)}</b></p>
                      
                      </div>
                      {/* <p>{user.role}</p> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Holiday Overlay Component */}
      <HolidayOverlay
        holidays={holidays}
        isVisible={isHolidayOverlayVisible}
        onClose={closeHolidayOverlay}
      />
      <ToastContainer />

      <div className='all-users-data1'>
  <h2>Manager Self Ratings</h2>
  {managerSelfRatings.length === 0 ? (
    <p>No self-ratings available.</p>
  ) : (
    <table style={{ marginLeft: '40px' }} className="users-table">
      <thead>
        <tr>
          <th>So No.</th>
          <th>Name</th>
          <th>Overall Avg</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {/* {console.log("Manager Self Rating  22222222222:", managerSelfRatings)}  */}
        {managerSelfRatings.map((rating,index) => ( 
          
          <React.Fragment key={rating._id}>
            <tr>
              <td>{index + 1}</td>
              <td>
                {getUserNameById(rating.employeeId)} 
                {/* { console.log("employeeId:", rating.employeeId) } */}
              </td>
              <td>
              {combinedAverages[rating?.employeeId] || 'N/A'} 
              
              </td>
              <td>
                <button onClick={() => handlemanagerRatingsExpansionAndModal(rating.employeeId._id)} > open</button>
                
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )}
</div>

{manageropenmodel && (
  <div className="expanded-modal-overlay" >
    <div className="expanded-table-modal">
      <button style={{ background: "red" }} onClick={closemanagerRatingsExpandedClick}>
        Close
      </button>
      {console.log("Manager Self Ratings 11111111:", managerSelfRatings)} 
      {managerSelfRatings
        .filter((rating) =>
          expandedEmployeeIds.includes(rating?.employeeId?._id)
        //  &&  rating?.formId?._id === selectedFormId
        )
        .map((rating) => (
          <div className="rating-details" key={rating?.answers.employeeId?._id}>
            <h4>Ratings Summary for {rating?.employeeId?.name || "name"}</h4>
            <div className="ratings-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
              
              {/* Manager Ratings Section */}
              {/* <div className="manager-ratings" style={{ flex: 1, padding: '10px' }}>
                <h3>Manager's Ratings</h3>
                <table className="ratings-table">
                  <thead>
                    <tr>
                      <th>Manager's Ratings<br /> Avg: {combinedAverages[rating?.employeeId._id] || 'N/A'}</th>
                    </tr>
                  </thead>
                  <tbody>
                  {console.log(rating,"123")}
                    {rating?.formId?.questions?.map((question, index) => {
                    const answer = rating?.answers?.find(a => a.questionIndex === index + 1)?.response || 'N/A';
                      const managerRating = managerRatings
                      
                        .filter(mRating => mRating?.employeeId?._id === rating?.employeeId?._id && mRating?.formId?._id === selectedFormId)
                        .map((mRating) => mRating?.answers?.find(a => a.questionIndex === index)?.response || 'N/A')[0];
                              
                      return (
                        <tr key={index}>
                          <td>
                            <div className="question">{question.label}</div>
                            
                            <div className="response">{answer}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div> */}
              
              <div className="manager-ratings" style={{ flex: 1, padding: '10px' }}>
  <h3>Manager's Ratings</h3>
  <table className="ratings-table">
    <thead>
      <tr>
        <th>Question Index</th>
        <th>Response</th>
      </tr>
    </thead>
    <tbody>
      {rating?.answers?.map((answer, index) => (
        <tr key={index}>
          <td>{answer.questionIndex}</td>
          <td>{answer.response ? answer.response : 'Rating not available'}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

              {/* CEO Ratings Section */}
              <div className="ceo-ratings" style={{ flex: 1, padding: '10px' }}>
                <h3>CEO's Ratings</h3>
                <table className="ratings-table">
                 <thead>
                   <tr>
                     <th>Question Index</th>
                     <th>Response</th>
                   </tr>
                 </thead>
                 <tbody>
                    {rating?.formId?.questions?.map((question, index) => {
                      const ceoRating = ceoRatings
                        .filter(cRating => cRating?.employeeId?._id === rating?.employeeId?._id && cRating?.formId?._id === selectedFormId)
                        .map((cRating) => cRating?.answers?.find(a => a.questionIndex === index)?.response || 'N/A')[0];

                      return (
                        <tr key={index}>
                          <td>
                            <div className="question">{question.label}</div>
                            <div className="response">{ceoRating}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            {/* If no CEO rating exists, show the rating form */}
            {!ceoRatings.some(cRating => cRating?.employeeId?._id === rating?.employeeId?._id && cRating) && (
              <>
                <h3>Rate The Employee:</h3>
                {forms.find(f => f?._id === selectedFormId)?.questions?.map((question, index) => (
                  <div key={index}>
                    <label>{question?.label}</label>
                    {question?.options && question?.options.length > 0 ? (
                      <select style={{ width: "50%" }}
                        onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                        value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                      >
                        <option value="">Select an option</option>
                        {question.options.map((option, i) => (
                          <option key={i} value={option}>{option}</option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type="text"
                        onChange={(e) => handleRatingChange(question?.label, e.target.value, rating?.employeeId?._id)}
                        value={answers?.[rating?.employeeId?._id]?.[question?.label] || ''}
                      />
                    )}
                  </div>
                ))}
              </>
            )}

            <h4>Give Feedback</h4>
            <textarea style={{ width: "50%" }}
              value={feedbacks?.[rating?.employeeId?._id] || ''}
              onChange={(e) => handleFeedbackChange(rating?.employeeId?._id, e.target.value)}
              placeholder="Enter your feedback here"
            />
            <div>
              <button style={{ backgroundColor: "#007bff", marginTop: "20px", width: "20%" }} onClick={() => handleSubmit(rating?.employeeId?._id)}>Submit</button>
            </div>
          </div>
        ))}
    </div>
  </div>
)}



{/* <div className='all-users-data1'>
  <h2>Manager Self Ratings</h2>
  {managerSelfRatings.length === 0 ? (
    <p>No self-ratings available.</p>
  ) : (
    <table style={{ marginLeft: '40px' }} className="users-table">
      <thead>
        <tr>
          <th>SL NO.</th>
          <th>	Name</th>
          <th>Overall Avg</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {managerSelfRatings.map((rating) => (
          <React.Fragment key={rating._id}>
            <tr>
              <td>1</td>
          
              <td>
              {rating.employeeId ? rating.employeeId.name : 'Unknown Employee'} em

                </td>

              <td>

              {combinedAverages[rating.employeeId] || 'N/A'}
              </td>

      
            
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )}
</div> */}





    </div>
    </>
  );
};

export default CEODashboard;

