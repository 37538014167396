import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../api/apiservice';
import './Employee.css';

const Employee = () => {
    const [userData, setUserData] = useState(null);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setMessage('No token found, please log in again.');
                    setMessageType('error');
                    return;
                }

                const { data } = await axios.get(`${server}auth/dashboard`, {
                    headers: { Authorization: `${token}` }
                });

                setUserData({
                    profileImage: data.profileImage || '',
                    name: data.name,
                    roleEmp: data.roleEmp,
                    email: data.email,
                    yourmanager: data.yourmanager,
                });
            } catch (error) {
                setMessage('Error fetching user data: ' + (error.response ? error.response.data.message : 'Network Error'));
                setMessageType('error');
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (messageType === 'error') {
        return <div className={`message ${messageType}`}>{message}</div>;
    }

    if (!userData) {
        return null;
    }

    return (
        <div className='employee-card'>
            <div className='profile-avatar-container'>
                <img
                    className='profile-avatar'
                    src={userData.profileImage ? `${server}${userData.profileImage}` : 'profilemen.avif'}
                    alt='Profile Avatar'
                />
            </div>
            <div className='employee-details'>
                <p><strong>Name:</strong> {userData.name}</p>
                <p><strong>Company Role:</strong> {userData.roleEmp}</p>
                <p><strong>Manager:</strong> {userData.yourmanager}</p>
                <p><strong>Email:</strong> {userData.email}</p>
            </div>
        </div>
    );
};

export default Employee;
