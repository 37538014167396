import React, { useState, useEffect } from "react";
import "./EmployeeFeedback.css"; // Import the CSS file
import { server } from "../../api/apiservice";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const EmployeeFeedback = ({ token, userId }) => {
  const [employee, setEmployee] = useState("");
  const [reason, setReason] = useState("");
  const [authority, setAuthority] = useState("");
  const [feedbackData, setFeedbackData] = useState(null);
  const [users, setUsers] = useState([]);
  const [managers, setManagers] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Find the selected employee's name from the users array
    const selectedEmployee = users.find((emp) => emp._id === employee);

    // Match backend field names exactly
    const data = {
      Employename: selectedEmployee?.name, // Send name instead of ID
      manager_id: authority,
      feedbacker_id: userId,
      Reason: reason,
    };

    // Validate all required fields
    if (!data.Employename || !data.manager_id || !data.Reason) {
      toast.error("Please fill all required fields");
      return;
    }

    try {
      const response = await axios.post(
        `${server}userRoutes/feedback`,
        data,
        {
          headers: { Authorization: `${token}` },
        }
      );

      toast.success("Successfully submitted feedback");
      // Clear form after success
      setEmployee("");
      setAuthority("");
      setReason("");
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error(error.response?.data?.message || "Error submitting feedback");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/users`, {
          headers: { Authorization: ` ${token}` },
        });
        // setUsers(response.data);
        const filteredUsers = response.data.filter((emp) => emp._id !== userId);
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUsers();
  }, [token]);

  useEffect(() => {
    // Fetch managers when the component mounts
    const fetchManagers = async () => {
      try {
        const { data } = await axios.get(`${server}userRoutes/managers`, {
          headers: { Authorization: ` ${token}` },
        });
        setManagers(data);
      } catch (error) {
        console.error("Error fetching managers:", error);
      }
    };

    fetchManagers();
  }, [token]);

  return (
    <div className="employeefeedback-wrapper">
      <div className="employeefeedback-container">
        <h2 className="employeefeedback-title">Employee Feedback Form</h2>
        <h5
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "#333",
            paddingTop: "0",
          }}
        >
          select the employee and authority to give feedback
        </h5>
        <form onSubmit={handleSubmit} className="employeefeedback-for">
          <div className="employeefeedback-form-group">
            <label htmlFor="employee" className="employeefeedback-label">
              Select Employee:
            </label>
            <select
              id="employee"
              value={employee}
              onChange={(e) => setEmployee(e.target.value)}
              className="employeefeedback-select"
              required
            >
              <option value="">Select Employee</option>
              {users.map((emp) => (
                <option key={emp._id} value={emp._id}>
                  {emp.name}
                </option>
              ))}
            </select>
          </div>

          <div className="employeefeedback-form-group">
            <label htmlFor="reason" className="employeefeedback-label">
              Reason:
            </label>
            <textarea
              id="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Enter the reason"
              className="employeefeedback-textarea"
              required
            />
          </div>

          <div className="employeefeedback-form-group">
            <label htmlFor="authority" className="employeefeedback-label">
              Select Authority:
            </label>
            <select
              id="authority"
              value={authority}
              onChange={(e) => setAuthority(e.target.value)}
              className="employeefeedback-select"
              required
            >
              <option value="">Select Authority</option>
              {managers.map((manager) => (
                <option key={manager._id} value={manager._id}>
                  {manager.name}
                </option>
              ))}
            </select>
          </div>

          <button type="submit" className="employeefeedback-submit-button">
            Submit Feedback
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
    // import this in employee dashboards
    /* <EmployeeFeedback token={localStorage.getItem("token")} userId={user._id}/> */
  );
};

export default EmployeeFeedback;
