import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApplyLeave.css';
import { server } from '../api/apiservice';

const ApplyLeave = ({ isOpen, toggleApplyLeave }) => {
  const [leaveType, setLeaveType] = useState('');
  const [leaveStartDate, setLeaveStartDate] = useState('');
  const [leaveEndDate, setLeaveEndDate] = useState('');
  const [leaveDuration, setLeaveDuration] = useState('Full Day');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const uName = localStorage.getItem("userName");

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setLeaveStartDate(today);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${server}leave/applyLeave`, {
        uName,
        leaveType,
        leaveStartDate,
        leaveEndDate: leaveEndDate || leaveStartDate,
        leaveDuration,
        reason,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
      });
      setMessage(response.data.message);
      // Clear form after submission
      setLeaveType('');
      setLeaveStartDate('');
      setLeaveEndDate('');
      setLeaveDuration('Full Day');
      setReason('');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Failed to apply for leave. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.querySelector('.apply-leave-container').contains(event.target)) {
        toggleApplyLeave();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const renderLeaveDurationOptions = () => {
    if (leaveStartDate === leaveEndDate) {
      return (
        <>
          <option value="Full Day">Full Day</option>
          <option value="Half Day">Half Day</option>
        </>
      );
    }
    return <option value="Full Day">Full Day</option>;
  };

  return (
    <>
      <div className="Apply-button-container">
        <button className="Apply-button-click" onClick={toggleApplyLeave} style={{ border: "none" }}>
          {/* <i className="fa-regular fa-pen-to-square"></i> */}
        </button>
      </div>
      {isOpen && (
        <div className='apply-overlay'>
          <div className="apply-leave-container">
            <h2>Apply for Leave</h2>
            <form className="apply-leave-form" onSubmit={handleSubmit}>
              <label htmlFor="leaveType">Leave Type:</label>
              <select
                id="leaveType"
                value={leaveType}
                onChange={(e) => setLeaveType(e.target.value)}
                required
              >
                <option value="">Select Leave Type</option>
                <option value="Sick Leave">Sick Leave</option>
                <option value="Casual Leave">Casual Leave</option>
                <option value="Annual Leave">Annual Leave</option>
              </select>

              <div className="date-fields">
                <div>
                  <label htmlFor="leaveStartDate">Start Date:</label>
                  <input
                    id="leaveStartDate"
                    type="date"
                    value={leaveStartDate}
                    onChange={(e) => setLeaveStartDate(e.target.value)}
                    min={new Date().toISOString().split('T')[0]}
                    required
                  />
                </div>
                {leaveStartDate && (
                  <div>
                    <label htmlFor="leaveEndDate">End Date:</label>
                    <input
                      id="leaveEndDate"
                      type="date"
                      value={leaveEndDate}
                      onChange={(e) => setLeaveEndDate(e.target.value)}
                      min={leaveStartDate}
                      required
                    />
                  </div>
                )}
              </div>

              <label htmlFor="leaveDuration">Leave Duration:</label>
              <select
                id="leaveDuration"
                value={leaveDuration}
                onChange={(e) => setLeaveDuration(e.target.value)}
              >
                {renderLeaveDurationOptions()}
              </select>

              <label htmlFor="reason">Reason for Leave:</label>
              <textarea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Enter your reason"
                required
              ></textarea>

              <button type="submit" disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </form>

            {message && (
              <p className={message.includes('successfully') ? 'message-success' : 'message-error'}>
                {message}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyLeave;
