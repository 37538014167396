import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScoreCircle = ({ averageScore }) => {
  // Handle undefined, null, or NaN values
  const normalizedScore = !averageScore || isNaN(averageScore) ? 0 : Math.min(averageScore, 5);
  // Calculate percentage (0-100)
  const percentage = Math.round((normalizedScore / 5) * 100);

  return (
    <div style={{ width: 100, height: 100 }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          textColor: "green",
          pathColor: "#0dc70a",
          trailColor: "gold",
          textSize: '24px',
        })}
      />
    </div>
  );
};

export default ScoreCircle;
