import React from "react";
import "./DetailsCard.css";
import {useState,useEffect} from "react";
import axios from 'axios';
import { server } from "../../api/apiservice";
import Image from "../../images/download.jpg"



function DetailsCard({ activeTab, user }) {
  const [educationDetails, setEducationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    degree: '',
    institution: '',
    yearOfGraduation: ''
  });

  let content;

  useEffect(() => {
    if (activeTab === 'education') {
      const token = localStorage.getItem('token');
  
      axios.get(`${server}ratings/edu-details/${user._id}`, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setEducationDetails(response.data.educationDetails); // Notice the .educationDetails
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching education details:', error.response?.data || error);
        setLoading(false);
      });
    }
  }, [activeTab, user._id]);

  const formattedDate = (dateString) => {
    return dateString.slice(0, 10);
  };

  const handleFormSubmit=(e)=>{
    const token = localStorage.getItem('token');
    e.preventDefault();
    console.log("formData:",formData)

    axios.post(`${server}ratings/education-details/${user._id}`, formData, {headers: {
      'Authorization': `${token}`,
      'Content-Type': 'application/json'
    }})
      .then(response => {
        console.log('Response:', response);
        console.log("Get for the education details",response.data.education)
        setEducationDetails(response.data.education);
        setShowForm(false);
      })
      .catch(error => {
        console.log('Error posting education details:', error);
      });
  }

  const handleInputChange=(e)=>{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  switch (activeTab) {
    case "employee":
      content = (
        <div>
          {/* <img src={Image} alt="" /> */}
          <h1 style={{ textAlign: "center" ,color:'#313b4c'}}>EMPLOYEE DETAILS</h1>
        <div className="infoDisplay">
          <div className='detailCard'>
          <p>🧑‍💼 Name  </p>
          <span>{user.name}</span>
          </div>
          <div className='detailCard'>
          <p>💼 Role  </p>
          <span>{user.role}</span>
          </div>
          <div className='detailCard'>
          <p>✉️ Email  </p>
          <span>{user.email}</span>
          </div>
          <div className='detailCard'>
          <p>📅 Joining Date  </p>
          <span>{formattedDate(user.joiningDate)}</span>
          </div>
          <div className='detailCard'>
          <p>🎂 D-O-B  </p>
          <span>{formattedDate(user.dateOfBirth)}</span>
          </div>
        </div>
        </div>
      );
      break;
    case "education":
      content = (
        <>
        {loading ? (
          <p>Loading...</p>
        ) :
         (
          <div className='submitEduDetails'>
          <h1>Submit Education Details</h1>
          <button onClick={() => setShowForm(true)}>Upload Education Details</button>
          </div>
        )}

        {showForm && (
          <form onSubmit={handleFormSubmit} className="educationForm">
            <label>
              Degree:
              <input
                type="text"
                name="degree"
                value={formData.degree}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Institution:
              <input
                type="text"
                name="institution"
                value={formData.institution}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Year of Graduation:
              <input
                type="text"
                name="yearOfGraduation"
                value={formData.yearOfGraduation}
                onChange={handleInputChange}
              />
            </label>
            <button type="submit">Submit</button>
          </form>
        )}
      </>
      )
      break;
    case "organization":
      content = (
        <ul className="itemDetails">
          <li className="card">
            <div className="cardHeader">
              🏢 <span className="cardLabel">Department:</span>
            </div>
            <p className="cardValue">{user.organization?.department}</p>
          </li>
          <li className="card">
            <div className="cardHeader">
              👤 <span className="cardLabel">Manager:</span>
            </div>
            <p className="cardValue">{user.organization?.manager}</p>
          </li>
          <li className="card">
            <div className="cardHeader">
              📍 <span className="cardLabel">Location:</span>
            </div>
            <p className="cardValue">{user.organization?.location}</p>
          </li>
        </ul>
      );
      break;
    default:
      content = <p>No details available</p>;
      break;
  }

  return (
    <div className="details">
      {content}
    </div>
  );
}

export default DetailsCard;