import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './EmployeeProfileDetails.css';
import { server } from '../../api/apiservice';

const EmployeeProfileDetails = () => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    // const profileRef = useRef(null); // Create a ref for the profile container

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         // Check if the click is outside of the profile container
    //         if (profileRef.current && !profileRef.current.contains(event.target)) {
    //             toggleProfileDetails();
    //         }
    //     };

    //     if (isOpen) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isOpen]);

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('No token found, please log in again.');
                    return;
                }

                const { data } = await axios.get(`${server}auth/dashboard`, {
                    headers: { Authorization: `${token}` }
                });

                setUserData({
                    name: data.name,
                    roleEmp: data.roleEmp,
                    yourmanager:data.yourmanager,
                    dateOfBirth: formatDate(data.dateOfBirth),
                    joiningDate: formatDate(data.joiningDate),
                    phoneNo: data.phoneNo || '',
                    gender: data.gender || '',
                    maritalStatus: data.maritalStatus || '',
                    degree: data.degree || '',
                    institution: data.institution || '',
                    percentage: data.percentage || '',
                    specialization: data.specialization || '',
                    companyName: data.companyName || '',
                    roleCompany: data.roleCompany || '',
                    cstartdate: formatDate(data.cstartdate) || '',
                    cenddate: formatDate(data.cenddate) || '',
                    skills: data.skills || '',
                    profileImage: data.profileImage || ''
                });
            } catch (error) {
                setError('Error fetching user data: ' + (error.response ? error.response.data.message : 'Network Error'));
                // console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    return (
        <div className="user-profile">
            <div className="profile-container">
                <div className="profile-section">
                    <h3>Personal Details</h3>
                    <table className="profile-table">
                        <tbody>
                            <tr>
                                <td><strong>Name:</strong></td>
                                <td>{userData.name}</td>
                                <td><strong>Company Role:</strong></td>
                                <td>{userData.roleEmp}</td>
                            </tr>
                            <tr>
                                <td><strong>Manager:</strong></td>
                                <td>{userData.yourmanager}</td>
                                <td><strong>Date of Birth:</strong></td>
                                <td>{userData.dateOfBirth}</td>
                            </tr>
                            <tr>
                                <td><strong>Joining Date:</strong></td>
                                <td>{userData.joiningDate}</td>
                                <td><strong>Phone No:</strong></td>
                                <td>{userData.phoneNo}</td>
                            </tr>
                            <tr>
                                <td><strong>Gender:</strong></td>
                                <td>{userData.gender}</td>
                                <td><strong>Marital Status:</strong></td>
                                <td>{userData.maritalStatus}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                

                <div className="profile-section">
                    <h3>Education Details</h3>
                    <table className="profile-table">
                        <tbody>
                            <tr>
                                <td><strong>Degree:</strong></td>
                                <td>{userData.degree}</td>
                                <td><strong>Institution:</strong></td>
                                <td>{userData.institution}</td>
                            </tr>
                            <tr>
                                <td><strong>Percentage:</strong></td>
                                <td>{userData.percentage}</td>
                                <td><strong>Specialization:</strong></td>
                                <td>{userData.specialization}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="profile-section">
                    <h3>Experience Details</h3>
                    <table className="profile-table">
                        <tbody>
                            <tr>
                                <td><strong>Company Name:</strong></td>
                                <td>{userData.companyName}</td>
                                <td><strong>Role in Company:</strong></td>
                                <td>{userData.roleCompany}</td>
                            </tr>
                            <tr>
                                <td><strong>Company Start Date:</strong></td>
                                <td>{userData.cstartdate}</td>
                                <td><strong>Company End Date:</strong></td>
                                <td>{userData.cenddate}</td>
                            </tr>
                            <tr>
                                <td><strong>Skills:</strong></td>
                                <td colSpan="3">{userData.skills}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EmployeeProfileDetails;
