import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Hr.css'
import SidebarAll from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { server } from '../../api/apiservice';
import HolidayOverlay from '../Calender/HolidayOverlay';
import Notifications from '../Notifications';
import { toast,ToastContainer} from 'react-toastify';
import MainDashboard from '../DashBoard/MainDashboard';
import NavigationBar from '../NavBar/NavigationBar';


const HRDashboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to track the clicked employee
  const [showFormsSection, setShowFormsSection] = useState(false);
  const [userRole, setUserRole] = useState('HR');
  const [showUsers, setShowUsers] = useState(false);
  const [topThreeUsers, setTopThreeUsers] = useState([]);
  const [activeSection, setActiveSection] = useState('dashboard');
  // const [activeComponent, setActiveComponent] = useState('dashboard');
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [holidays, setHolidays] = useState([
    { date: '26 Jan', name: 'Republic Day ', imageUrl: 'republic.jpg' },
    { date: '8 Mar', name: 'Maha Shivaratri', imageUrl: 'shivratri.avif' },
    { date: '25 Mar', name: 'Holi', imageUrl: 'holi.avif' },
    { date: '9 Apr', name: 'Ugadi', imageUrl: 'ugadi.webp' },
    { date: '1 May', name: 'Labour Day ', imageUrl: 'labour.jpeg' },
    { date: '15 Aug', name: 'Independence Day', imageUrl: 'indi.jpg' },
    { date: '7 Sep', name: 'Ganesh Chaturthi', imageUrl: 'ganesh.jpeg' },
    { date: '2 Oct', name: 'Gandhi Jayanti', imageUrl: 'gandhi.jpg' },
    { date: '11 Oct', name: 'Maha Navami', imageUrl: 'navami.avif' },
    { date: '12 Oct', name: 'Dusshera', imageUrl: 'dus.jpeg' },
    { date: '1 Nov', name: 'Kannada Rajyothsava / Diwali', imageUrl: 'kan.jpeg' },
    { date: '5 Nov', name: 'Balipadyami, Deepavali', imageUrl: 'diwali.jpeg' },
    { date: '25 Dec', name: 'Christmas', imageUrl: 'christmas.jpg' },
  ]);

  const getOrdinalSuffix = (index) => {
    const suffixes = ["st", "nd", "rd"];
    const remainder = index % 10;
    const suffix = remainder > 0 && remainder < 4 && ![11, 12, 13].includes(index) ? suffixes[remainder - 1] : "th";
    return `${index}${suffix}`;
  };
  const [isHolidayOverlayVisible, setIsHolidayOverlayVisible] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleHolidaysClick = () => {
    setIsHolidayOverlayVisible(true);
  };

  const closeHolidayOverlay = () => {
    setIsHolidayOverlayVisible(false);
  };

  const handleDashboardClick = () => {
    setActiveSection('dashboard');
  };

  // Check authentication on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/'); // Redirect to login if not authenticated
    }
  }, [navigate]);


  const handleAttendance = async (userId, status) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${server}userRoutes/mark-attendance`, {
        userId,
        status,
      }, {
        headers: { Authorization: `${token}` },
      });

      console.log(`User ${userId} marked as ${status}`);
      setSelectedUserId(null); // Optionally reset the selected user after selecting an option
    } catch (error) {
      console.error('Error marking attendance:', error);
    }
  };


  // Fetch all users on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${server}userRoutes/users`, {
          headers: { Authorization: `${token}` },
        }); // Update the URL to your API endpoint
        setUsers(response.data); // Set the users data
        console.log("ghjkjhg",response.data);
        
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const token = localStorage.getItem('token');
  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${server}/userRoutes/users`, {
        headers: { Authorization: `${token}` },
      }); // Update the URL to your API endpoint
      setUsers(response.data); // Set the users data
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Function to handle when a user clicks the employee name
  const handleUserClick = (userId) => {
    setSelectedUserId(userId === selectedUserId ? null : userId); // Toggle selection
  };

  // const handleShowUsers = () => {
  //   setShowUsers(true);
  //   setShowFormsSection(false);
  //   setActiveSection('users')
  //   if (!showUsers) {
  //     fetchUsers();
  //   }
  // };

  // Show users section
  const handleShowUsers = () => {
    setActiveSection('users');
    setShowFormsSection(false);
  };

  // const handleSignout = () => {
  //   localStorage.removeItem('token');
  //   toast.success("SignOut successfully")
  //   navigate('/');
  // };
  const handleSignout = () => {
    

    toast(<CustomLogoutToast />);
  };

  const CustomLogoutToast = ({ closeToast }) => (
    <div >
      <p style={{color:"white"}}>Are you sure you want to Sign out?</p>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          onClick={() => {
            localStorage.removeItem("token");
            toast.success("Successfully logged out!");
            navigate("/"); // navigate after logging out
            closeToast(); // close the toast
          }}
          style={{ borderRadius: "20px", cursor: "pointer" }}
        >
          Yes
        </button>
        <button onClick={closeToast} style={{  borderRadius: "20px",  cursor: "pointer"}}>Cancel</button>
      </div>
    </div>
  );

  // I am adding Fetch top 3 highest rated employees
  // useEffect(() => {
  //   if (activeSection === 'topRated') {
  //     fetchTopThreeUsers();
  //   }
  // }, [activeSection]);

  const fetchTopThreeUsers = async () => {
    try {
      const response = await axios.get(`${server}userRoutes/topThreeRatings`);
      setTopThreeUsers(response.data.ratings);
      console.log("Res",response)
      console.log("Res",response.employeeId)
      

    } catch (error) {
      console.error('Error fetching top 3 rated users:', error);
    }
  };

  const handleTopRatedClick = () => {
    setActiveSection('topRated');

    setShowFormsSection(false);
    fetchTopThreeUsers();
  };

  return (
    <>
     <NavigationBar />
    <div className="hr-dashboard">
     
      <SidebarAll
        onDashboard={handleDashboardClick} onSignout={handleSignout} onShowUsers={handleShowUsers} onTopRated={handleTopRatedClick} onHolidays={handleHolidaysClick} userRole={userRole}
      />
    


      {/* <div className='ccc'>
        <h1>HR Dashboard</h1>
      </div> */}
      {activeSection==='dashboard' &&(
        <MainDashboard />
      )}
      <div className='.all-users-data1' style={{ margin: "70px 0px 0px 50px" }}>
        {activeSection === 'users' && (
          <table style={{ marginLeft: '70px' }} className="users-table">
            <thead>
              <tr>
                {/* <th>ID</th> */}
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                {/* <th>Attendance</th> */}
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <React.Fragment key={user._id}>
                  <tr>
                    {/* <td>{user._id}</td> */}
                    <td onClick={() => handleUserClick(user._id)} style={{ cursor: 'pointer', color: 'blue' }}>
                      {user.name}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    {/* <td>
                  {selectedUserId === user._id && (
                    <div className="attendance-options">
                      <button onClick={() => handleAttendance(user._id, 'Present')}>Present</button>
                      <button onClick={() => handleAttendance(user._id, 'Absent')}>Absent</button>
                    </div>
                  )}
                </td> */}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {activeSection === 'topRated' && (
        <div className='hrmain'>
          <div className="overview-cards">
            <div className="card">
              <h1>Top Rated Employees</h1>
              {/* Top 3 Highest Rated Employees */}
              <div className="top-three">
                <div className="top-three-container">
                  {topThreeUsers.map((user, index) => (
                    <div
                      key={user._id}
                      className={`top-user-card top-user-${index + 1}`}
                    >
                      <img 
                      // src="profilemen.avif" 
                      src={
                        user.profileImage
                          ? `${server}${user.profileImage}`
                          : "profilemen.avif"
                      } 
                      alt={user.employeeName} className="top-user-avatar" />
                   
                      <div className='top-user-details'>
                        <p>{user.employeeName}</p>
                        <p><b>{getOrdinalSuffix(index + 1)}</b></p>
                        {/* <p>Rating: {user.overallAverage}</p> */}
                      </div>
                      {/* <p>{user.role}</p> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
       {/* Holiday Overlay Component */}
       <HolidayOverlay
          holidays={holidays}
          isVisible={isHolidayOverlayVisible}
          onClose={closeHolidayOverlay}
        />
        <ToastContainer />
    </div>
    </>

  );
};

export default HRDashboard;
