import React from 'react';
import SelfRatingForm from './SelfRatingForm';
import './FormDetails.css'

const FormDetails = () => {
  return (
    <div className='data'>
      <div className='Form'>
        <SelfRatingForm />
      </div>
    </div>
  );
};

export default FormDetails;
