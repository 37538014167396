import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./ProfileDetails.css";
import DetailsCard from './DetailsCard';

function ProfileDetails() {
  const location = useLocation();
  const { user } = location.state || {};
  const [activeTab, setActiveTab] = useState("employee");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

const toggleSidebar = () => {
  setIsSidebarVisible(!isSidebarVisible);
};

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="profileContainer">
      <div className="navbarToggle" onClick={toggleSidebar}>
      ☰ {/* Hamburger icon */}
    </div>
      {isSidebarVisible && <div className="employeeDetailSideBar">
        <ul className="employeeDetailOptions">
          <img
            className="imageEmployee"
            src="https://picsum.photos/600"
            alt="Employee"
          />
          <li 
            onClick={() => handleClick("employee")} 
            className={`employeeDetails ${activeTab === "employee" ? "active" : ""}`}
          >
            Employee Details
          </li>
          <li 
            onClick={() => handleClick("education")} 
            className={`educationDetails ${activeTab === "education" ? "active" : ""}`}
          >
            Education Details
          </li>
          <li 
            onClick={() => handleClick("organization")} 
            className={`organizationTree ${activeTab === "organization" ? "active" : ""}`}
          >
            Organization Tree
          </li>
        </ul>
      </div>}
      <div className="detailsCardContainer">
        <DetailsCard activeTab={activeTab} user={user} />
      </div>
    </div>
  );
}

export default ProfileDetails;
