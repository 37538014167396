import React, { useEffect, useState } from "react";
import axios from "axios";
import "./leaveCalender.css";
import {server} from '../api/apiservice'; 

const LeaveCalendar = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());

  useEffect(() => {
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get(`${server}attendace/attendance`); 
        if (response.data && response.data.attendence) {
          setAttendanceData(response.data.attendence); 
        } else {
          console.error("Attendance data not found in response:", response.data);
          setAttendanceData([]); // Set to empty array if data is not found
        }
        setLoading(false);
        console.log('attendance- response', response);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setLoading(false);
      }
    };

    fetchAttendanceData();
  }, []);

  const renderCalendar = () => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    const calendar = [];

    // Create empty slots for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      calendar.push(<div className="empty" key={`empty-${i}`}></div>);
    }

    // Create date blocks
    for (let day = 1; day <= daysInMonth; day++) {
      const dateKey = `${year}-${month + 1}-${day}`;
      const attendance = Array.isArray(attendanceData) ? attendanceData.find((entry) => entry.date.startsWith(dateKey)) : null; // Adjusted to use startsWith for date comparison
      let className = "date-block";

      if (attendance) {
        // Only mark as present or absent if attendance data exists
        className += attendance.status.toLowerCase() === "present" ? " present" : " absent"; // Adjusted to use lowercase for comparison
      } else {
        // If no attendance data, you can choose to leave it as is or add a class for no data
        className += " no-data"; // Optional: Add a class for days with no data
      }

      // Check if the current day is a Sunday
      if (new Date(year, month, day).getDay() === 0) {
        className += " sunday"; // Add class for Sundays
      }

      calendar.push(
        <div className={className} key={day}>
          {day}
        </div>
      );
    }

    // Add empty slots for the end of the month to fill the last row
    const totalSlots = calendar.length;
    const totalRows = Math.ceil(totalSlots / 5); // Calculate total rows based on 5 columns
    const totalCells = totalRows * 5; // Total cells needed

    for (let i = totalSlots; i < totalCells; i++) {
      calendar.push(<div className="empty" key={`empty-end-${i}`}></div>);
    }

    return calendar;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="calendar">
      <h2>{`${year} - ${month + 1}`}</h2>
      <div className="calendar-grid" style={{marginTop:"0px"}}>{renderCalendar()}</div>
    </div>
  );
};

export default LeaveCalendar;
